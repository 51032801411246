import template from './send-email-item.component.html';

const styles = require('./send-email-item.component.scss');

import ng, { IWindowService } from 'angular';
import log from 'loglevel';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { generateBlockuiId } from '../../utilities/generate-blockui-id';
import { generateGrowlId } from '../../utilities/generate-growl-id';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { Email } from '../../models/email';
import { switchOn } from '../../utilities/switch-on';
import { CustomersService } from '~/source/contact/common/services/customers';
import { Customer, User, Brand } from '@proftit/crm.api.models.entities';
import { ExternalWindowService } from '../../services/external-window.service';
import { ModelNormalizerService } from '../../services/model-normalizer';
import { EmailPreview } from '../../models/email-preview';
import { FormControl } from '@proftit/ng1.reactive-forms';
import { requestWithComponent } from '../../utilities/request-with-component';
import { TokensService } from '~/source/auth/services/tokens';
import { UsersService } from '~/source/management/user/services/users';
import { Base64 } from 'js-base64';
import { IPromise } from 'restangular';
import { PermissionNormalized } from '~/source/common/models/permission-structure';
import { checkCrudPermission } from '~/source/common/utilities/rxjs/observables/check-crud-permission';

enum FormType {
  TemplateEmail = 'templateEmail',
  CustomEmail = 'customEmail',
}

export class SendEmailItemController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  customer: Customer;
  onCancel: () => void;
  onDone: () => void;

  blockUiId = generateBlockuiId();
  growlId = generateGrowlId();
  title = 'sendEmailItem.TITLE';

  opPreviewEmail$ = new rx.Subject<void>();
  opSendEmail$ = new rx.Subject<void>();

  customer$ = observeShareCompChange<Customer>(
    this.lifecycles.onChanges$,
    'customer',
  );
  brand$ = observeShareCompChange<Brand>(this.lifecycles.onChanges$, 'brand');

  emailTypeOptions$ = this.streamEmailTypeOptions();

  emailTypeCtrl$ = rx.pipe(
    () => rx.obs.from([new FormControl<FormType>(FormType.CustomEmail)]),
    shareReplayRefOne(),
  )(null);

  currentUserId$ = rx.pipe(
    () => rx.obs.from([this.tokensService.getCachedUser()]),
    rx.map((user) => user.id),
    shareReplayRefOne(),
  )(null);

  senderName$ = rx.pipe(
    () => rx.obs.from(this.currentUserId$),
    rx.switchMap(
      (userId) =>
        requestWithComponent(
          (s) =>
            s
              .embed(['brands', 'brands.emails'])
              .getOneWithQuery(userId) as Promise<User>,
          () => rx.obs.NEVER,
          this.usersService(),
          this,
        ) as rx.Observable<User>,
    ),
    rx.withLatestFrom(this.brand$),
    rx.map(([user, brand]) => {
      const brandFound = (user as any).brands.find((b) => b.id === brand?.id);
      const userBrandEmail = _.get(['emails', 0, 'email'], brandFound);
      const userEmail = user.email;

      return {
        userEmail,
        userBrandEmail,
      };
    }),
    rx.map(({ userEmail, userBrandEmail }) =>
      _.defaultTo(userEmail, userBrandEmail),
    ),
    shareReplayRefOne(),
  )(null);

  customMailModelFromTypeInit$ = rx.pipe(
    () =>
      this.emailTypeCtrl$.pipe(
        rx.switchMap((ctrl) => ctrl.value$ as rx.Observable<string>),
      ),
    rx.switchMap(() => this.senderName$),
    rx.map((senderName) => newCustomEmail({ from: senderName })),
    shareReplayRefOne(),
  )(null);

  customMailModel$ = this.streamCustomMailModel();

  customMailFormModel$ = new rx.BehaviorSubject<any>({});

  templateMailModelFromTypeInit$ = rx.pipe(
    () =>
      this.emailTypeCtrl$.pipe(
        rx.switchMap((ctrl) => ctrl.value$ as rx.Observable<string>),
      ),
    rx.switchMap(() => this.senderName$),
    rx.map((senderName) => newTemplateEmail({ from: senderName })),
    shareReplayRefOne(),
  )(null);

  templateEmailModel$ = rx.pipe(
    () => rx.obs.merge(this.customMailModelFromTypeInit$),
    shareReplayRefOne(),
  )(null);

  templateMailFormModel$ = new rx.BehaviorSubject<any>({});

  isCustomMailModelValid$ = new rx.BehaviorSubject<boolean>(false);
  isTemplateMailModelValid$ = new rx.BehaviorSubject<boolean>(false);

  isModelValid$ = this.streamIsModelValid();

  isPreviewDisplayed$ = this.streamIsPreviewDisplayed();
  isPreviewEnabled$ = this.streamIsPreviewEnabled();

  contactsCommunicationsEmailSendFromPerm$ = checkCrudPermission(
    PermissionNormalized.ContactsCommunicationsEmailSendFrom,
    this.PermPermissionStore,
  ).pipe(shareReplayRefOne());

  /*@ngInject */
  constructor(
    readonly customersService: () => CustomersService,
    readonly externalWindowService: ExternalWindowService,
    readonly $window: IWindowService,
    readonly modelNormalizer: ModelNormalizerService,
    readonly $translate: angular.translate.ITranslateService,
    readonly tokensService: TokensService,
    readonly usersService: () => UsersService,
    readonly PermPermissionStore: ng.permission.PermissionStore,
  ) {
    useStreams(
      [
        this.isPreviewDisplayed$,
        this.customer$,
        this.brand$,
        this.streamPreviewEmail(),
        this.streamSendEmail(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamEmailTypeOption(option) {
    return rx.pipe(
      () => rx.obs.from([option.labelCode]),
      rx.switchMap((code) =>
        rx.obs.from((this.$translate(code) as any) as Promise<string>),
      ),
      rx.map((text) => ({
        value: option.value,
        label: text,
      })),
    )(null);
  }

  streamEmailTypeOptions() {
    return rx.pipe(
      () =>
        rx.obs.from([
          [
            {
              value: FormType.CustomEmail,
              labelCode: 'sendEmailItem.emailTypeOptions.CUSTOM_EMAIL',
            },
            {
              value: FormType.TemplateEmail,
              labelCode: 'sendEmailItem.emailTypeOptions.TEMPLATE_EMAIL',
            },
          ],
        ]),
      rx.map((options) =>
        options.map((opt) => this.streamEmailTypeOption(opt)),
      ),
      rx.switchMap((opts$List) => rx.obs.forkJoin(opts$List)),
      shareReplayRefOne(),
    )(null);
  }

  streamIsModelValid() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.emailTypeCtrl$.pipe(
            rx.switchMap((ctrl) => ctrl.value$ as rx.Observable<string>),
          ),
          this.isCustomMailModelValid$,
          this.isTemplateMailModelValid$,
        ),
      rx.map(
        ([emailType, isCustomMailModelValid, isTemplateMailModelValid]) => {
          return switchOn(
            {
              [FormType.TemplateEmail]: () => isTemplateMailModelValid,
              [FormType.CustomEmail]: () => isCustomMailModelValid,
            },
            emailType,
            () => {
              throw new Error('unimplemented');
            },
          );
        },
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamCustomMailModel() {
    return rx.pipe(
      () => rx.obs.merge(this.customMailModelFromTypeInit$),
      shareReplayRefOne(),
    )(null);
  }

  streamPreviewEmail() {
    return rx.pipe(
      () => this.opPreviewEmail$,
      rx.withLatestFrom(
        this.emailTypeCtrl$.pipe(rx.switchMap((ctrl) => ctrl.value$)),
        this.customer$,
        this.customMailFormModel$,
        this.templateMailFormModel$,
      ),
      rx.switchMap(
        ([a, emailType, customer, customMailModel, templateEmailModel]) => {
          return switchOn(
            {
              [FormType.TemplateEmail]: () =>
                this.generateCustomerEmailTemplatePreview(
                  customer.id,
                  templateEmailModel.template.id,
                ),
              [FormType.CustomEmail]: () => {
                throw new Error('unimplemented');
              },
            },
            emailType as any,
            () => {
              throw new Error('unimplemented');
            },
          );
        },
      ),
      rx.map((preview: EmailPreview) => Base64.decode(preview.html)),
      rx.tap((content) =>
        this.externalWindowService.openContentInCenter(content),
      ),
      rx.catchError((err, caught) => {
        log.error('error in previewing email', err);
        return rx.obs.EMPTY;
      }),
    )(null);
  }

  streamSendEmail() {
    return rx.pipe(
      () => this.opSendEmail$,
      rx.withLatestFrom(
        this.emailTypeCtrl$.pipe(rx.switchMap((ctrl) => ctrl.value$)),
        this.customMailFormModel$,
        this.templateMailFormModel$,
      ),
      rx.map(([a, emailType, customEmailModel, templateEmailModel]) => {
        return switchOn(
          {
            [FormType.TemplateEmail]: () => templateEmailModel,
            [FormType.CustomEmail]: () => customEmailModel,
          },
          emailType as any,
          () => {
            throw new Error('unimplemented');
          },
        );
      }),
      rx.withLatestFrom(
        this.contactsCommunicationsEmailSendFromPerm$.pipe(
          rx.map((perm) => perm.isCreate),
        ),
      ),
      rx.map(([model, editFromEmailPerm]) => {
        let nModel = _.omit(['id'], model);
        if (!editFromEmailPerm) {
          nModel = _.omit(['from'], nModel);
        }
        return nModel;
      }),
      rx.map((model) => this.modelNormalizer.normalize(model)),
      rx.withLatestFrom(this.customer$),
      rx.switchMap(([email, customer]) =>
        rx.obs
          .from(
            this.customersService()
              .setConfig({
                growlRef: this.growlId,
                blockUiRef: this.blockUiId,
              })
              .createEmail(customer.id, email),
          )
          .pipe(
            rx.tap(() => this.onDone()),
            rx.catchError((err, caught) => {
              log.error('error in sending email', err);
              return rx.obs.NEVER;
            }),
          ),
      ),
    )(null);
  }

  streamIsPreviewDisplayed() {
    return rx.pipe(
      () => this.emailTypeCtrl$.pipe(rx.switchMap((f) => f.value$)),
      rx.map((formType) => formType === FormType.TemplateEmail),
      shareReplayRefOne(),
    )(null);
  }

  streamIsPreviewEnabled() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.isModelValid$, this.isPreviewDisplayed$),
      rx.map(([isModelValid, isPreviewDisplayed]) => {
        return isPreviewDisplayed && isModelValid;
      }),
      shareReplayRefOne(),
    )(null);
  }

  generateCustomerEmailTemplatePreview(
    customerId: number,
    contentTemplateId: number,
  ) {
    return rx.obs.from(
      this.customersService()
        .setConfig({
          growlRef: this.growlId,
          blockUiRef: this.blockUiId,
        })
        .getEmailPreview(customerId, contentTemplateId),
    );
  }

  onCancelClick() {
    this.onCancel();
  }
}

function newTemplateEmail(params = {}): Email {
  return {
    id: null,
    from: null,
    ...params,
  };
}

function newCustomEmail(params = {}): Email {
  return {
    id: null,
    from: null,
    ...params,
  };
}

export const SendEmailItemComponent = {
  template,
  controller: SendEmailItemController,
  bindings: {
    customer: '<',
    brand: '<',
    onCancel: '&',
    onDone: '&',
  },
};
