import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as _ from '@proftit/lodash';
import template from './deposits-prop.component.html';
const styles = require('../../common-report-table.component.scss');

export class DepositsPropController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  /* @ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const DepositsPropComponent = {
  bindings: {
    brand: '<',
  },
  template,
  controller: DepositsPropController,
  require: {
    prfCrmAppStoreProvider: '^',
    prfTranslationsProvider: '^',
  },
};
