export const challengesReportsSettings = {
  table: {
    ngTable: {
      parameters: {
        count: 50,
        page: 1,
        sorting: {
          transactionDate: 'desc',
        },
      },
      settings: {
        counts: [],
      },
    },
    quickFilters_comment:
      'if quick filter is active by default. you must set its value in bgTable.parameters.filter above',
    quickFilters: {
      reportsRealContacts: {
        state: {
          isActive: true,
        },
      },
    },
    colsList: [
      {
        title: 'ID',
        field: 'id',
        fieldName: 'id',
        fieldFormat: '{{ id }}',
        reportFields: ['id'],
        filterable: false,
        sortable: 'id',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'STARTING_AT',
        field: 'startingAt',
        fieldName: 'startingAt',
        fieldFormat: '{{ startingAt }}',
        reportFields: ['startingAt'],
        filterable: false,
        sortable: 'startingAt',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'CHALLENGE_NAME',
        field: 'challengeName',
        fieldName: 'challengeName',
        fieldFormat: '{{ challengeName }}',
        reportFields: ['challengeName'],
        filterable: false,
        sortable: 'challengeName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'CHALLENGE_TYPE',
        field: 'challengeType',
        fieldName: 'challengeType',
        fieldFormat: '{{ challengeType }}',
        reportFields: ['challengeType'],
        filterable: false,
        sortable: 'challengeType',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'CUSTOMER_ID',
        field: 'customerId',
        fieldName: 'customerId',
        fieldFormat: '{{ customerId }}',
        reportFields: ['customerId'],
        filterable: false,
        sortable: 'customerId',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'CUSTOMER_NAME',
        field: 'customerName',
        fieldName: 'customerName',
        fieldFormat: '{{ customerName }}',
        reportFields: ['customerName'],
        filterable: false,
        sortable: 'customerName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'CURRENCY',
        field: 'currency',
        fieldName: 'currency',
        fieldFormat: '{{ currency }}',
        reportFields: ['currency'],
        filterable: false,
        sortable: 'currency',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'COUNTRY',
        field: 'country',
        fieldName: 'country',
        fieldFormat: '{{ country }}',
        reportFields: ['country'],
        filterable: false,
        sortable: 'country',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'BRAND',
        field: 'brand',
        fieldName: 'brand',
        fieldFormat: '{{ brand }}',
        reportFields: ['brand'],
        filterable: false,
        sortable: 'brand',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'DESK',
        field: 'desk',
        fieldName: 'desk',
        fieldFormat: '{{ desk }}',
        reportFields: ['desk'],
        filterable: false,
        sortable: 'desk',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'EMAIL',
        field: 'email',
        fieldName: 'email',
        fieldFormat: '{{ email }}',
        reportFields: ['email'],
        filterable: false,
        sortable: 'email',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'EMPLOYEE',
        field: 'employee',
        fieldName: 'employee',
        fieldFormat: '{{ employee }}',
        reportFields: ['employee'],
        filterable: false,
        sortable: 'employee',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'ENTRY_FEE',
        field: 'entryFee',
        fieldName: 'entryFee',
        fieldFormat: '{{ entryFee }}',
        reportFields: ['entryFee'],
        filterable: false,
        sortable: 'entryFee',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'STARTING_BALANCE',
        field: 'startingBalance',
        fieldName: 'startingBalance',
        fieldFormat: '{{ startingBalance }}',
        reportFields: ['startingBalance'],
        filterable: false,
        sortable: 'startingBalance',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'STRUCTURE',
        field: 'structure',
        fieldName: 'structure',
        fieldFormat: '{{ structure }}',
        reportFields: ['structure'],
        filterable: false,
        sortable: 'structure',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'PROFIT_STRUCTURE',
        field: 'profitStructure',
        fieldName: 'profitStructure',
        fieldFormat: '{{ profitStructure }}',
        reportFields: ['profitStructure'],
        filterable: false,
        sortable: 'profitStructure',
        removable: true,
        show: true,
        type: 'common',
      },
    ],
  },
  assignUsersLimit: 10000,
  userEvents: {
    toggleItem: 'toggleItem',
    paging: 'paging',
  },
};
