import { PlatformCode } from '@proftit/crm.api.models.enums';
import { PermissionNormalized } from '~/source/common/models/permission-structure';

interface MenuEntry {
  permissionOnly: string;
  svgIcon: string;
  title: string;
  uiState: string;
  enabledForPlatforms: PlatformCode[];
}

export const menuEntries: MenuEntry[] = [
  {
    permissionOnly: 'management.riskmanager',
    svgIcon: 'symbols',
    title: 'riskManager.SYMBOLS',
    uiState: '.symbols',
    enabledForPlatforms: [
      PlatformCode.Cfd,
      PlatformCode.Bundle,
      PlatformCode.Mt4,
      PlatformCode.Mt5,
      PlatformCode.PROP,
    ],
  },
  {
    permissionOnly: 'management.riskmanager',
    svgIcon: 'securities',
    title: 'riskManager.SECURITIES',
    uiState: '.securities',
    enabledForPlatforms: [
      PlatformCode.Mt4,
      PlatformCode.Mt5,
      PlatformCode.PROP,
    ],
  },
  {
    permissionOnly: 'management.riskmanager',
    svgIcon: 'groups',
    title: 'riskManager.GROUPS',
    uiState: '.groups',
    enabledForPlatforms: [
      PlatformCode.Cfd,
      PlatformCode.Bundle,
      PlatformCode.Mt4,
      PlatformCode.Mt5,
      PlatformCode.PROP,
    ],
  },
  {
    permissionOnly: 'management.riskmanager',
    svgIcon: 'brands',
    title: 'riskManager.BRANDS',
    uiState: '.brands',
    enabledForPlatforms: [
      PlatformCode.Cfd,
      PlatformCode.Bundle,
      PlatformCode.PROP,
    ],
  },
];
