import * as _ from '@proftit/lodash';
import { depositsWithdrawalsReportsSettings } from './deposits-withdrawals-prop-report-settings';
const USER_SETTING_REPORTS_COLUMNS_KEY =
  'deposits-withdrawalsPropReportsTableColumnsV2';
import template from '../../common-reports-table.component.html';
import { CommonReportsTableController } from '../../common-reports-table.component';

export class depositsWithdrawalsPropReportTableController extends CommonReportsTableController {
  resource: string = 'depositsWithdrawals';
  exportResource: string = 'deposits_withdrawals';

  /* @ngInject */
  constructor(...args) {
    super(
      <any>depositsWithdrawalsReportsSettings,
      USER_SETTING_REPORTS_COLUMNS_KEY,
      'depositsWithdrawalsPropReportTable',
      args,
    );
  }

  get tableKey() {
    // for save the user setting we need to add it to the server const,
    // this will error now...
    return 'deposits_withdrawals_prop_report_table';
  }
}

export const DepositsWithdrawalsPropReportTableComponent = {
  bindings: {
    brand: '<',
  },
  template,
  controllerAs: 'vm',
  controller: depositsWithdrawalsPropReportTableController,
};
