import * as _ from '@proftit/lodash';
import { creditsReportsSettings } from './credits-prop-report-settings';
const USER_SETTING_REPORTS_COLUMNS_KEY = 'creditsPropReportsTableColumnsV2';
import template from '../../common-reports-table.component.html';
import { CommonReportsTableController } from '../../common-reports-table.component';

export class creditsReportTableController extends CommonReportsTableController {
  resource: string = 'credits';
  exportResource: string = 'credits';

  /* @ngInject */
  constructor(...args) {
    super(
      <any>creditsReportsSettings,
      USER_SETTING_REPORTS_COLUMNS_KEY,
      'creditsPropReportTable',
      args,
    );
  }

  get tableKey() {
    // for save the user setting we need to add it to the server const,
    // this will error now...
    return 'credits_prop_report_table';
  }
}

export const CreditsPropReportTableComponent = {
  bindings: {
    brand: '<',
  },
  template,
  controllerAs: 'vm',
  controller: creditsReportTableController,
};
