import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './challenge-account-info.component.html';
import {
  Challenge,
  ChallengePhasePayload,
  ChallengePhase,
} from '@proftit/crm.api.models.entities/dist/chalenges-types';
import {
  Currency,
  Customer,
  CHALLENGE_TYPES,
} from '@proftit/crm.api.models.entities';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { TokensService } from '~/source/auth/services/tokens';
import { observeChannel } from '~/source/common/utilities/observe-channel';
import TradingAccountForexSocketService from '~/source/contact/contact-page/trading-account/forex/trading-account-socket.service';
import ChallengeSocketService from '~/source/contact/contact-page/prop-account/challenge-account-container/challenge-socket.service';
import log from 'loglevel';

const styles = require('./challenge-account-info.component.scss');

//todo remove this, then backend support will be ready
const demoPurchase = {
  id: 25,
  amountRequested: 348,
  amountApproved: 348,
  amountApprovedBasic: 0,
  amountWithdrawable: 7,
  transferMethodTypeCode: 'WIRE_DEPOSIT',
  transactionStatusCode: 'requested',
  bankAccountId: null,
  bonusTransactionId: null,
  isOriginCustomer: true,
  requestedAt: '2024-08-19 00:00:00',
  receivedDate: null,
  rejectionReason: null,
  html3d: null,
  redirectUrl: null,
  is3dSale: false,
  metadata: null,
  showWidget: null,
  formHtml: null,
  transactionCode: null,
  note: 'gdgsdgdgdfgdhgd',
  isChargeBack: false,
  syncRemoteId: '',
  tradingAccountId: 7,
  customerId: 3,
  isDeletable: false,
  siteLanguage: null,
  currency: {
    id: 1,
    code: 'USD',
    name: 'United States dollar',
    number: 840,
    decimals: 2,
    isCrypto: false,
  },
  user: null,
  owner: null,
  ownerDesk: null,
  clearingCompany: null,
  transactionTransferWire: {
    id: 13,
    firstName: null,
    lastName: null,
    accountNumber: null,
    bankName: 'UniCredit Titiac Bank',
    bankAddress: '53/57 Grzybowska street, 2nd floor, Warsaw',
    bankCity: null,
    bankCountryId: 225,
    swiftCode: 'PKOPPLPW',
    iban: 'PL67 1240 6247 1787 0000 49774100',
    confirmedBy: null,
    transactionId: null,
    holderName: '',
    customFields: [],
  },
  transactionTransferCreditCard: null,
  transactionTransferApm: null,
  ewalletTransaction: null,
  mobileTransaction: null,
  attachments: [],
};
type ChallengePurchase = {
  id: number;
  amountRequested: number;
  amountApproved: number;
  amountApprovedBasic: number;
  amountWithdrawable: number;
  transferMethodTypeCode: string;
  transactionStatusCode: string;
  bankAccountId: number;
  bonusTransactionId: number;
  isOriginCustomer: boolean;
  requestedAt: string;
  receivedDate: string;
  rejectionReason: string;
  html3d: string;
  redirectUrl: string;
  is3dSale: boolean;
  metadata: string;
  showWidget: string;
  formHtml: string;
  transactionCode: string;
  note: string;
  isChargeBack: boolean;
  syncRemoteId: string;
  tradingAccountId: number;
  customerId: number;
  isDeletable: boolean;
  siteLanguage: string;
  currency: Currency;
  user: any;
  owner: any;
  ownerDesk: any;
  clearingCompany: any;
  transactionTransferWire: {
    id: number;
    firstName: string;
    lastName: string;
    accountNumber: string;
    bankName: string;
    bankAddress: string;
    bankCity: string;
    bankCountryId: number;
    swiftCode: string;
    iban: string;
    confirmedBy: any;
    transactionId: any;
    holderName: string;
    customFields: any[];
  };
  transactionTransferCreditCard: any;
  transactionTransferApm: any;
  ewalletTransaction: any;
  mobileTransaction: any;
  attachments: any[];
};

export class ChallengeAccountInfoController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  challenge: Challenge;
  customer: Customer;
  totalEarning: number;
  purchase$ = this.streamCustomerChallengePurchase();
  user$ = this.streamUser();

  /*@ngInject*/
  constructor(
    readonly $translate: angular.translate.ITranslateService,
    readonly tokensService: TokensService,
    readonly tradingAccountForexSocketService: TradingAccountForexSocketService,
    readonly challengeSocketService: ChallengeSocketService,
  ) {
    useStreams([this.purchase$, this.user$], this.lifecycles.onDestroy$);
  }

  $onInit() {
    useStreams(
      [
        this.streamFundedTradingAccountToCrmSocket(),
        this.streamChallengeToCrmSocket(),
      ],
      this.lifecycles.onDestroy$,
    );
    this.setTotalEarning();
  }

  streamChallengeToCrmSocket() {
    return rx.obs
      .combineLatest(
        this.user$.pipe(rx.filter((service) => !_.isNil(service))),
        rx.obs.of(this.challenge),
      )
      .pipe(
        rx.switchMap(([user, challenge]) => {
          const crmChannelName = `user.${user.id}.CustomerChallenge.${challenge.id}`;
          return observeChannel(
            this.challengeSocketService,
            crmChannelName,
          ).pipe(
            rx
              .map((itemUpdates: any) => {
                this.challenge.status = itemUpdates.status;
              })
              .bind(this),
          );
        }),
        rx.catchError((err) => {
          log.error('Error in observing CRM account updates', err);
          return rx.obs.EMPTY;
        }),
      );
  }

  streamFundedTradingAccountToCrmSocket() {
    return rx.obs
      .combineLatest(
        this.user$.pipe(rx.filter((service) => !_.isNil(service))),
        rx.obs.of(this.challenge.phases),
      )
      .pipe(
        rx.switchMap(([user, phases]) => {
          const validPhases = (phases as ChallengePhase[]).filter(
            (phase) =>
              phase.status === 'active' &&
              phase.isFunded &&
              phase.tradingAccount !== null,
          );

          if (validPhases.length === 0) {
            return rx.obs.EMPTY;
          }
          const crmChannelName = `user.${user.id}.CustomerTradingAccount.${validPhases[0].tradingAccount.id}`;
          return observeChannel(
            this.tradingAccountForexSocketService,
            crmChannelName,
          ).pipe(
            rx
              .map((itemUpdates: any) => {
                this.totalEarning =
                  itemUpdates.withdrawal + itemUpdates.withdrawable;
              })
              .bind(this),
          );
        }),
        rx.catchError((err) => {
          log.error('Error in observing CRM account updates', err);
          return rx.obs.EMPTY;
        }),
      );
  }

  $onDestroy() {}

  $onChanges() {}

  streamUser() {
    return rx.pipe(
      () => this.lifecycles.onInitShared$,
      rx.filter((isInit) => isInit),
      rx.map(() => this.tokensService.getCachedUser()),
      shareReplayRefOne(),
    )(null);
  }

  streamCustomerChallengePurchase() {
    return rx.pipe(
      () => this.lifecycles.onChanges$,
      rx.map(() => [this.customer.id, this.challenge.id]),
      rx.switchMap(([customerId, challengeId]) => {
        return rx.obs.from(
          this.fetchCustomerChallengePurchases(customerId, challengeId),
        );
      }),
      rx.map((data) => {
        return data;
      }),
      shareReplayRefOne(),
    )(null);
  }

  getProfitStructure(): string {
    const brokerLabel = this.$translate.instant(
      'challenges.phase.earning.BROKER',
    );
    const traderLabel = this.$translate.instant(
      'challenges.phase.earning.TRADER',
    );
    const fixedPrizeLabel = this.$translate.instant(
      'challenges.phase.earning.FIXED_PRIZE',
    );

    const phases = this.challenge?.phases;
    const lastPhase = phases?.[phases.length - 1] as ChallengePhasePayload;
    const calculationType =
      lastPhase?.fields?.profitSplit?.calculationType ?? null;
    const profit = lastPhase?.fields?.profitSplit?.value ?? 0;

    switch (calculationType) {
      case 'percentage':
        return `${100 - profit}% ${brokerLabel} / ${profit}% ${traderLabel}`;
      case 'fixed':
        return `${profit}% ${fixedPrizeLabel}`;
      default:
        return '';
    }
  }

  isTrial(): boolean {
    return this.challenge.type === CHALLENGE_TYPES.TRIAL;
  }

  setTotalEarning() {
    const fundedPhase = (this.challenge?.phases as ChallengePhase[])?.find(
      (phase) => phase.isFunded,
    );
    const tradingAccount = fundedPhase?.tradingAccount as {
      [key: string]: any;
    };
    this.totalEarning =
      (tradingAccount?.withdrawable || 0) + (tradingAccount?.withdrawal || 0);
  }

  async fetchCustomerChallengePurchases(
    customerId: number,
    challengeId: number,
  ): Promise<ChallengePurchase> {
    //todo replace with real data
    //console.log('fetchCustomerChallengePurchases', customerId, challengeId);
    return Promise.resolve(demoPurchase);
  }
}

export const ChallengeAccountInfoComponent = {
  template,
  controller: ChallengeAccountInfoController,
  bindings: {
    challenge: '<',
    customer: '<',
  },
};
