export const feesReportsSettings = {
  table: {
    ngTable: {
      parameters: {
        count: 50,
        page: 1,
        sorting: {
          updateTime: 'desc',
        },
      },
      settings: {
        counts: [],
      },
    },
    quickFilters_comment:
      'if quick filter is active by default. you must set its value in bgTable.parameters.filter above',
    quickFilters: {
      reportsRealContacts: {
        state: {
          isActive: true,
        },
      },
    },
    colsList: [
      {
        title: 'contactsImportTable.fields.CREATED_AT',
        field: 'createdAt',
        fieldName: 'createdAt',
        fieldFormat: '{{ createdAt }}',
        reportFields: ['createdAt'],
        filterable: true,
        sortable: 'createdAt',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'CUSTOMER_ID',
        field: 'customerId',
        fieldName: 'customerId',
        fieldFormat: '{{ customerId }}',
        reportFields: ['customerId'],
        filterable: false,
        sortable: 'customerId',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.CUSTOMER_NAME',
        field: 'customerName',
        fieldName: 'customerName',
        fieldFormat: '{{ customerName }}',
        reportFields: ['customerName'],
        filterable: false,
        sortable: 'customerName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'EMAIL',
        field: 'email',
        fieldName: 'email',
        fieldFormat: '{{ email }}',
        reportFields: ['email'],
        filterable: false,
        sortable: 'email',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'PHONE',
        field: 'phone',
        fieldName: 'phone',
        fieldFormat: '{{ phone }}',
        reportFields: ['phone'],
        filterable: false,
        sortable: 'phone',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.FEE_STATUS',
        field: 'feeStatus',
        fieldName: 'feeStatus',
        fieldFormat: '{{ feeStatus }}',
        reportFields: ['feeStatus'],
        filterable: true,
        sortable: 'feeStatus',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.CUSTOMER_STATUS',
        field: 'customerStatusName',
        fieldName: 'customerStatusName',
        fieldFormat: '{{ customerStatusName }}',
        reportFields: ['customerStatusName'],
        filterable: true,
        sortable: 'customerStatusName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'BRAND',
        field: 'reportsBrand',
        fieldName: 'reportsBrand',
        fieldFormat: '{{ brand }}',
        reportFields: ['brand'],
        filterable: true,
        sortable: 'brand',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.DESK',
        field: 'reportsDesk',
        fieldName: 'reportsDesk',
        fieldFormat: '{{ desk }}',
        reportFields: ['desk'],
        filterable: true,
        sortable: 'desk',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.OFFER_NAME',
        field: 'offerName',
        fieldName: 'offerName',
        fieldFormat: '{{ offerName }}',
        reportFields: ['offerName'],
        filterable: false,
        sortable: 'offerName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'auditLogs.MARKETING_INFO',
        field: 'marketingInfo',
        fieldName: 'marketingInfo',
        fieldFormat: '{{ marketingInfo }}',
        reportFields: ['marketingInfo'],
        filterable: false,
        sortable: 'marketingInfo',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.CAMPAIGN',
        field: 'reportsCampaign',
        fieldName: 'campaign',
        fieldFormat: '{{ campaign }}',
        reportFields: ['campaign'],
        filterable: true,
        sortable: 'campaign',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reporrts.titles.PROCESSING_EMPLOYEE',
        field: 'processedBy',
        fieldName: 'processedBy',
        fieldFormat: '{{ processedBy }}',
        reportFields: ['processedBy'],
        filterable: true,
        sortable: 'processedBy',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.TOTAL_PURCHASES',
        field: 'depositTotal',
        fieldName: 'depositTotal',
        fieldFormat: '{{ depositTotal | number : 2 }}',
        reportFields: ['depositTotal'],
        filterable: true,
        sortable: 'depositTotal',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.TOTAL_PURCHASES_COUNT',
        field: 'totalDepositsCnt',
        fieldName: 'totalDepositsCnt',
        fieldFormat: '{{ totalDepositsCnt }}',
        reportFields: ['totalDepositsCnt'],
        filterable: true,
        sortable: 'totalDepositsCnt',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.CHALLENGE_NAME',
        field: 'challengeName',
        fieldName: 'challengeName',
        fieldFormat: '{{ challengeName }}',
        reportFields: ['challengeName'],
        filterable: false,
        sortable: 'challengeName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'filters.LAST_LOGIN_DATE',
        field: 'lastLoginDate',
        fieldName: 'lastLoginDate',
        fieldFormat: '{{ lastLoginDate }}',
        reportFields: ['lastLoginDate'],
        filterable: true,
        sortable: 'lastLoginDate',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.LAST_TRADE_DATE',
        field: 'lastTradeDate',
        fieldName: 'lastTradeDate',
        fieldFormat: '{{ lastTradeDate }}',
        reportFields: ['lastTradeDate'],
        filterable: true,
        sortable: 'lastTradeDate',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'portfolio.table.TRADING_ACCOUNT_ID',
        field: 'tradingAccountId',
        fieldName: 'tradingAccountId',
        fieldFormat: '{{ tradingAccountId }}',
        reportFields: ['tradingAccountId'],
        filterable: false,
        sortable: 'tradingAccountId',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.ACCOUNT_CASH_PNL',
        field: 'accountCashPnl',
        fieldName: 'accountCashPnl',
        fieldFormat: '{{ accountCashPnl | number : 2 }}',
        reportFields: ['accountCashPnl'],
        filterable: true,
        sortable: 'accountCashPnl',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'common.ACCOUNT_MANAGER',
        field: 'accountManager',
        fieldName: 'accountManager',
        fieldFormat: '{{ accountManager }}',
        reportFields: ['accountManager'],
        filterable: true,
        sortable: 'accountManager',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.ACCOUNT_WITHDRAWABLE_BALANCE',
        field: 'accountWithdrawableBalance',
        fieldName: 'accountWithdrawableBalance',
        fieldFormat: '{{ accountWithdrawableBalance | number : 2 }}',
        reportFields: ['accountWithdrawableBalance'],
        filterable: true,
        sortable: 'accountWithdrawableBalance',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'common.AMOUNT',
        field: 'amount',
        fieldName: 'amount',
        fieldFormat: '{{ amount | number : 2 }}',
        reportFields: ['amount'],
        filterable: true,
        sortable: 'amount',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.AMOUNT_BASIC',
        field: 'amountBasic',
        fieldName: 'amountBasic',
        fieldFormat: '{{ amountBasic | baseCurrencyFormat : 2 : true }}',
        reportFields: ['amountBasic'],
        filterable: true,
        sortable: 'amountBasic',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'portfolio.stats.TOTAL_BALANCE',
        field: 'balanceTotal',
        fieldName: 'balanceTotal',
        fieldFormat: '{{ balanceTotal | number : 2 }}',
        reportFields: ['balanceTotal'],
        filterable: true,
        sortable: 'balanceTotal',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.KYC_STATUS',
        field: 'reportsCustomerComplianceName',
        fieldName: 'customerComplianceName',
        fieldFormat: '{{ customerComplianceName }}',
        reportFields: ['customerComplianceName'],
        filterable: true,
        sortable: 'customerComplianceName',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.CUSTOMER_WITHDRAWABLE_BALANCE',
        field: 'customerWithdrawableBalance',
        fieldName: 'customerWithdrawableBalance',
        fieldFormat: '{{ customerWithdrawableBalance | number : 2 }}',
        reportFields: ['customerWithdrawableBalance'],
        filterable: true,
        sortable: 'customerWithdrawableBalance',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'fees.FEE_TYPE',
        field: 'reportsFeeType',
        fieldName: 'feeType',
        fieldFormat: '{{ feeType }}',
        reportFields: ['feeType'],
        filterable: true,
        sortable: 'feeType',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.FTD_DAYS_COUNT',
        field: 'ftdDaysCount',
        fieldName: 'ftdDaysCount',
        fieldFormat: '{{ ftdDaysCount }}',
        reportFields: ['ftdDaysCount'],
        filterable: true,
        sortable: 'ftdDaysCount',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.LAST_CALL_DATE',
        field: 'lastCallDate',
        fieldName: 'lastCallDate',
        fieldFormat: '{{ lastCallDate }}',
        reportFields: ['lastCallDate'],
        filterable: true,
        sortable: 'lastCallDate',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'contact.LAST_CALL_STATUS',
        field: 'lastCallStatus',
        fieldName: 'lastCallStatus',
        fieldFormat: '{{ lastCallStatus }}',
        reportFields: ['lastCallStatus'],
        filterable: true,
        sortable: 'lastCallStatus',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'PHONE2',
        field: 'phone2',
        fieldName: 'phone2',
        fieldFormat: '{{ phone2 }}',
        reportFields: ['phone2'],
        filterable: false,
        sortable: 'phone2',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.TOTAL_PNL',
        field: 'pnlTotal',
        fieldName: 'pnlTotal',
        fieldFormat: '{{ pnlTotal | number : 2 }}',
        reportFields: ['pnlTotal'],
        filterable: true,
        sortable: 'pnlTotal',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.REGISTRATION_DATE_COUNT',
        field: 'registrationDateCount',
        fieldName: 'registrationDateCount',
        fieldFormat: '{{ registrationDateCount }}',
        reportFields: ['registrationDateCount'],
        filterable: true,
        sortable: 'registrationDateCount',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'LAST_UPDATED_DATE',
        field: 'updateTime',
        fieldName: 'updateTime',
        fieldFormat: '{{ updateTime }}',
        reportFields: ['updateTime'],
        filterable: true,
        sortable: 'updateTime',
        removable: true,
        show: false,
        type: 'common',
      },
    ],
  },
  assignUsersLimit: 10000,
  userEvents: {
    toggleItem: 'toggleItem',
    paging: 'paging',
  },
};
