import _ from 'underscore';
import moment from 'moment';
import BaseController from '~/source/common/controllers/base';
import template from './creditcard-deposit.html';
import { IHttpService, IScope } from 'angular';
import CreditCardTypesService from '~/source/contact/common/services/credit-card-types.service';
import BinCheckerService from '~/source/contact/common/services/bin-checker.service';
import { CreditCard } from '~/source/common/models/credit-card';

const MIN_BINCHECK_LENGTH = 6;

class CreditCardDepositController extends BaseController {
  // bindings
  creditCard: CreditCard;

  cvvDigits: number;
  cvvImageSuffix: string;
  months: string[];
  years: string[];
  cardTypes;
  binData;
  _lastBinChecked: string;
  hideAmount: boolean;

  /*@ngInject*/
  constructor(
    readonly $scope: IScope,
    readonly $http: IHttpService,
    readonly creditCardTypesService: () => CreditCardTypesService,
    readonly binCheckerService: BinCheckerService,
  ) {
    super();
    // reset bin data object
    this.binData = false;
    // get types pf credit cards from server
    this.creditCardTypesService()
      .exclude('code', 'other')
      .getListWithQuery()
      .then((cardTypes) => {
        this.cardTypes = cardTypes;
      });
    /*
     * 13 because range "stop" is exclusive.
     * toString because there is a bug in ui-select with numbers
     */
    this.months = _.range(1, 13).map((month) => month.toString());
    const currentYear = moment().year();
    const lastYear = currentYear + 10;
    // +1 because range "stop" is exclusive
    this.years = _.range(currentYear, lastYear + 1).map((year) =>
      year.toString(),
    );
    // When the card type changes, update the cvv number of digits and image prefix for CVV tooltip
    this.$scope.$watch('vm.creditCard.creditcardType.code', (code) => {
      // only amex uses 4 digits and therefore has its own tooltip image
      this.cvvDigits = code === 'amex' ? 4 : 3;
      this.cvvImageSuffix = code === 'amex' ? '-amex' : '';
    });
  }

  /**
   * Get bin data from external service for given ccNum
   *
   * @param {string} ccNum
   */
  binCheck(ccNum) {
    if (_.isUndefined(ccNum)) {
      return;
    }
    /*
     * preform the bin check only if we got
     * at least 6 digits
     */
    if (ccNum.length < MIN_BINCHECK_LENGTH) {
      this.binData = false;
      this.creditCard.creditcardType = {};
      return;
    }
    /*
     * if equal to last ccNum that has been checked - prevent
     * the request
     */
    if (
      !_.isEmpty(this.lastBinChecked) &&
      ccNum.indexOf(this.lastBinChecked) === 0
    ) {
      return;
    }
    // get bin data
    this.binCheckerService
      .check(ccNum)
      .then((binData) => this.onBinDataReceived(binData))
      .catch(() => this.binCheckFailed());
    // save number that we gone to check
    this.lastBinChecked = ccNum;
  }

  /**
   * Called when bin data has been received from service and updates
   * credit card type accordingly
   *
   * @param {Object} binData
   */
  onBinDataReceived(binData) {
    if (binData === false) {
      // invalid ccNum - no valid bin received
      this.binCheckFailed();
    }
    // match bit card type with supported card types
    this.creditCard.creditcardType = _.findWhere(this.cardTypes, {
      code: binData.card,
    });
    // set bin data
    this.binData = binData;
  }

  /**
   * Called on when error returned from bin data provider
   */
  binCheckFailed() {
    this.binData = false;
  }

  /**
   * Saves last bin number that was checked. Reduces requests to server
   *
   * @param {string|*|string} ccNum
   */
  set lastBinChecked(ccNum) {
    this._lastBinChecked = ccNum.substring(0, MIN_BINCHECK_LENGTH);
  }

  /**
   * Get last bin number that was checked
   *
   * @returns {string|*|string}
   */
  get lastBinChecked() {
    return this._lastBinChecked || '';
  }
}

export default {
  template,
  controllerAs: 'vm',
  controller: CreditCardDepositController,
  bindings: {
    creditCard: '=',
    hideAmount: '<',
  },
};
