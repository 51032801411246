import type { IHttpResponse, IDeferred, IRequestShortcutConfig } from 'angular';
import _ from 'underscore';
import * as _l from '@proftit/lodash';
import log from 'loglevel';
import BaseService from './baseService';
import { PrimitiveValue } from '~/source/types/PrimitiveValue';
import ICollectionPromiseRestNg from '~/source/common/models/icollection-promise-rest-ng';
import isAssertionError from '~/source/common/utilities/is-assertion-error';
import formatAssertionError from '~/source/common/utilities/format-assertion-error';
import { CacheOptions } from 'cachefactory';
import { IRestConfig } from './irest-config';
import { isGrowlSuppressed } from './rest/is-growl-suppress';
/**
 * Name of the header that contains total number of entities
 * Useful for pagination
 */
const TOTAL_ELEMENTS_HEADER = 'x-total-count';
const SLICE_START = '_start';
const SLICE_END = '_end';
const SLICE_LIMIT = '_limit';
const SORT = '_sort';
const ORDER = '_order';
const SEARCH = 'q';
const OPERATOR_EXCLUDE = '_ne';
const OPERATOR_LTE = '_lte';
const OPERATOR_GTE = '_gte';
const OPERATOR_EXPAND = '_expand[]';
const OPERATOR_EMBED = '_embed[]';

const exceptionMessageRegex = new RegExp('for exception');

export type SortDirection = 'asc' | 'desc';
type SortObject = { [key: string]: SortDirection };

type ResponseInterceptor<T> = (
  data: T,
  operation: string,
  what: string,
  url: string,
  response: IHttpResponse<T>,
  deferred: IDeferred<any>,
) => any;

export type RequestInterceptor = (
  element: any,
  operation: string,
  what: string,
  url: string,
  headers: any,
  params: any,
  httpConfig: IRequestShortcutConfig,
) => { element: any; headers: any; params: any };

export interface IRestServiceInstance {
  total: number;
  setConfig: Function;
  embed: Function;
  cacheEmpty: Function;
  filter: Function;
  sort: Function;
}

export abstract class RestService extends BaseService
  implements IRestServiceInstance {
  // Services
  protected Restangular: Restangular.IService;
  protected mockService: Restangular.IService;
  protected $httpParamSerializer: angular.IHttpParamSerializer;
  protected $translate: angular.translate.ITranslateService;
  protected appConfig;
  protected CacheFactory: CacheFactory.ICacheFactory;
  protected blockUI;
  protected growl;
  protected growlMessages;

  // Service instances
  private blockUiInstance;
  private restangularInstance: Restangular.IService;
  private restangularResource: Restangular.IService;
  private restService;
  private originalService: Restangular.IService;

  lastResponseHeaders: object;

  queryParams: object = {};
  config: IRestConfig = {
    blockUiRef: this.name,
    growlRef: this.name,
    defaultErrorMessage: this.defaultErrorMessage,
    errorsTranslationPath: 'server_errors', // translation path for errors
    suppressGrowl: false,
    dataCodesForGrowlSuppress: [],
    suppressBlockUi: false,
  };

  static $inject = [
    'Restangular',
    'mockService',
    'appConfig',
    '$httpParamSerializer',
    'CacheFactory',
    'blockUI',
    'growl',
    'growlMessages',
    '$translate',
  ];

  public constructor(...args) {
    super(...args);
    this.initRestangular();
  }

  /**
   * Initialize the restangular instance with all the needed configuration (url, interceptors etc)
   * and also initialize a restangular service.
   * @return {void}
   */
  initRestangular(): void {
    // use either mock or real server
    const restInstance = this.useMock() ? this.mockService : this.Restangular;

    this.restangularInstance = restInstance.withConfig(
      (RestangularConfigurer) => {
        // Define all the request interceptors
        this.setRequestInterceptors(RestangularConfigurer);
        // define all the response interceptors
        this.setResponseInterceptors(RestangularConfigurer);
        // define the default error handler
        RestangularConfigurer.setErrorInterceptor(
          this.errorInterceptor.bind(this),
        );

        if (!this.useMock()) {
          // for the real server only, set the base url
          RestangularConfigurer.setBaseUrl(this.baseUrl);
        }
      },
    );

    // Also init a restangular service
    this.restService = this.restangularInstance.service(this.resource);
  }

  /**
   * Get the base API url.
   * e.g. http://crm.proftit.com/api/user/v1
   *
   * The resource should be added to this url.
   *
   * @returns {string}
   */
  get baseUrl(): string {
    let url = `${this.appConfig.connections.api}${
      this.appConfig.apiPrefix[this.accessType]
    }`;
    if (this.accessType !== 'legacy') {
      // For all apis except legacy, version is needed
      url += `/${this.apiVersion}`;
    }

    return url;
  }

  /**
   * Get the api version configured for this server
   *
   * Default is "v3". Can be overridden by subclass.
   *
   * @return {string} api version with "v" prefix
   */
  get apiVersion(): string {
    return 'v4';
  }

  /**
   * Resource name. must be overridden
   * @return {string}
   */
  abstract get resource(): string;

  /**
   * Resource access type: user/customer/public.
   * Will be used to determine which api prefix to use.
   *
   * The default is defined here, but can be overriden by subclasses.
   * @returns {string}
   */
  get accessType(): string {
    return 'user';
  }

  /**
   * Service name. used as the growl ref and block ui id.
   * Should be overriden by subclasses.
   *
   * @returns {string}
   */
  get name(): string {
    return 'restService';
  }

  /**
   * Default error message for server errors
   * Can be overidden by subclasses, or by setting a different default message per request.
   *
   * @returns {string}
   */
  get defaultErrorMessage(): string {
    return null;
  }

  responseInterceptors<T>(): ResponseInterceptor<T>[] {
    return null;
  }

  get requestInterceptors(): RequestInterceptor[] {
    return null;
  }

  /**
   * Error interceptor used by restangular. Called automatically when the server returns an error code.
   *
   * It will show the error message returned by the server in the configured growl reference.
   * If no error message was returned by the server it will show the default error message.
   *
   * It will always return 'false' to tell restangular the error was handled
   *
   * @param {object} response - Restangular response
   * @param {promise} deferred - Restangular request promise
   * @returns {boolean} - always false to prevent restangular from rejecting the deferred object
   */
  errorInterceptor(
    response: Restangular.IResponse,
    deferred: angular.IDeferred<any>,
  ): boolean {
    const statusCode = response.status;
    const errorMessageParse = this.parseError(response, statusCode);
    const growlRef = this.config.growlRef;

    if (!isGrowlSuppressed(response, this.config)) {
      errorMessageParse.then((errorMessage) => {
        this.growl.error(errorMessage, { referenceId: growlRef });
      });
    }

    // Stop blocking the ui
    if (this.blockUiInstance) {
      // force-stop the block ui
      this.blockUiInstance.reset();

      // set the block ui and growl ref back to its default values after each request
      this.config.blockUiRef = this.name;
      this.config.growlRef = this.name;
    }

    // Reject the promise with a server error
    errorMessageParse.then((errorMessage) => {
      const err: any = new Error(errorMessage);
      err.isServerError = true;
      // attach server response to error instance. only pick the "interesting" fields (we don't want a long exception)
      err.response = {
        config: _.pick(response.config, ['method', 'url']),
        data: _.pick(response.data, ['code', 'message']),
        status: response.status,
      };
      deferred.reject(err);
    }); // we don't need to handle "catch" as the "parse" is ALWAYS resolved.

    return false; // marks the error as handled, since we are rejecting the promise ourselves.
  }

  /**
   * Parse general ajax error response
   */
  parseError(
    response: Restangular.IResponse,
    statusCode: number,
  ): Promise<string> {
    if (isAssertionError(response)) {
      const msg = formatAssertionError(response);
      return Promise.resolve(msg) as any;
    }

    return this.parseServerError(response, statusCode);
  }

  /**
   * Parse error code from the server response and return it's translation
   *
   * It first tries to find this error code in the configured translation path,
   * then default translation path.
   * If error code is not found - returns default error message code.
   *
   * Since we handle all the errors internally, the returned promise will ALWAYS be resolved
   *
   * @param {object} response - Server response object
   * @param {number} statusCode - Response status code
   * @returns {Promise} - resolved with the parsed error message (never rejected)
   */
  parseServerError(
    response: Restangular.IResponse,
    statusCode: number,
  ): Promise<string> {
    const translationPath = this.config.errorsTranslationPath;
    /*
     * since the translation is async, "this.config" might change until it occurs
     * therefore, we must save the vars we need BEFORE the translation starts. so don't inline:
     */
    const defaultError = this.config.defaultErrorMessage;

    let errorCode = response.data && response.data.code;

    if (!errorCode) {
      // If there is no error code, use status code instead
      switch (statusCode) {
        case -1: // Requested aborted. Original status unknown (can be 404, 504 or simply offline)
          errorCode = 'SERVER_UNREACHABLE';
          break;
        case 522: // server timeout (cloudflare)
          errorCode = 'SERVER_TIMEOUT';
          break;
        case 523: // server is unreachable (cloudflare)
          errorCode = 'SERVER_DOWN';
          break;
        default:
          errorCode = null; // 'SERVER_GENERAL';
          break;
      }
    }

    // First try to find this error in the configured translation path
    return (
      ((this.$translate(`${translationPath}.${errorCode}`) as any) as Promise<
        string
      >)
        // Not found, try to find it in the general errors
        .catch(
          () =>
            ((this.$translate(`server_errors.${errorCode}`) as any) as Promise<
              string
            >).catch(() => {
              if (!_l.isNil(defaultError)) {
                return defaultError;
              }

              const serverMessage = _l.get(['data', 'message'], response);

              if (exceptionMessageRegex.test(serverMessage)) {
                return 'An unknown server error has occurred, please try again later.';
              }

              return `A server error has occured: ${serverMessage}`;
            }), // Still not found: show default error message
        )
    );
  }

  /**
   * Merges the passed config object with the RestService current config.
   *
   * Returns self, for chaining.
   * @param {IRestConfig} config
   * @returns {this}
   */
  setConfig(config: IRestConfig): this {
    Object.assign(this.config, config);

    return this;
  }

  /**
   * List of default response interceptors
   * @returns {*[]}
   */
  get defaultResponseInterceptors(): ResponseInterceptor<any>[] {
    return [
      this.headersResponseInterceptor,
      this.blockUiStopResponseInterceptor,
    ];
  }

  /**
   * List of default (full) request interceptors
   * @returns {*[]}
   */
  get defaultRequestInterceptors(): Function[] {
    return [
      this.cacheSetRequestInterceptor,
      this.cacheClearRequestInterceptor,
      this.blockUiRequestInterceptor,
      this.growlRequestInterceptor,
    ];
  }

  /**
   * Iterate through the default + user defined response interceptors and add each of them
   * to the RestangularConfigurer.
   *
   * @param {object} RestangularConfigurer
   */
  setResponseInterceptors(RestangularConfigurer): void {
    // start with the default response interceptors
    let interceptors = this.defaultResponseInterceptors;

    // Then add user defined interceptors, if exists
    if (Array.isArray(this.responseInterceptors)) {
      interceptors = interceptors.concat(this.responseInterceptors);
    }

    // finally, add all of them to restangular
    interceptors.forEach((fn) => {
      RestangularConfigurer.addResponseInterceptor(fn.bind(this));
    });
  }

  /**
   * Iterate through the default + user defined (full) request interceptors and add each of them
   * to the RestangularConfigurer.
   *
   * @param {object} RestangularConfigurer
   */
  setRequestInterceptors(RestangularConfigurer): void {
    // start with the default request interceptors
    let interceptors = this.defaultRequestInterceptors;

    // Then add user defined interceptors, if exists
    if (Array.isArray(this.requestInterceptors)) {
      interceptors = interceptors.concat(this.requestInterceptors);
    }

    // finally, add all of them to restangular
    interceptors.forEach((fn) => {
      RestangularConfigurer.addFullRequestInterceptor(fn.bind(this));
    });
  }

  /**
   * A response interceptor which sets the headers to the instance, so they could be
   * available for the user.
   * @param {object} data - The data received got from the server
   * @param {string} operation - The operation made
   * @param {object} what - The model that's being requested
   * @param {string} url - The relative URL being requested
   * @param {object} response - Full server response including headers
   * @returns {*}
   */
  headersResponseInterceptor<T>(
    data: T,
    operation: string,
    what: string,
    url: string,
    response: angular.IHttpResponse<T>,
  ): T {
    // set response headers
    this.lastResponseHeaders = response.headers();

    return data;
  }

  /**
   * Resets (removes) all growl message for the configured growl ui ref.
   *
   * The growl messages are usually set by the error handler.
   */
  growlRequestInterceptor(): void {
    this.growlMessages.destroyAllMessages(this.config.growlRef);
  }

  /**
   * Blocks (using angular-block-ui) the configured block-ui element, unless suppressBlockUi is true.
   *
   * This will actually increase the block count by 1, and could be called multiple times.
   * So if for example this was called twice, stop will also have to be called twice in order to unblock.
   */
  blockUiRequestInterceptor(): void {
    if (this.config.suppressBlockUi) {
      return;
    }

    log.debug(
      'Starting block ui for %s (resource: %s)',
      this.config.blockUiRef,
      this.resource,
    );
    this.blockUiInstance = this.blockUI.instances.get(this.config.blockUiRef);
    if (this.blockUiInstance) {
      this.blockUiInstance.start();
    }
  }

  /**
   * Stops blocking the block-ui element.
   *
   * This will actually decrease the block count by 1 and will only unblock when the count drops to zero.
   * @param {*} data
   * @returns {*} returns the data as-is (required by the interceptor definition)
   */
  blockUiStopResponseInterceptor<T>(data: T) {
    if (!this.blockUiInstance) {
      return data;
    }

    log.debug(
      'Stopping block ui for %s (resource: %s)',
      this.config.blockUiRef,
      this.resource,
    );
    this.blockUiInstance.stop();

    // set the block ui ref back to its default value after each request
    this.config.blockUiRef = this.name;

    return data;
  }

  /**
   * Sets the cache factory when using getList and when setCache is true.
   *
   * @param {object} element - The element to send to the server.
   * @param {string} operation - The operation made
   * @param {object} what - The model that's being requested
   * @param {string} url - The relative URL being requested
   * @param {object} headers - The headers to send
   * @param {object} params - The request parameters to send
   * @param {object} httpConfig - The httpConfig to call with
   */
  cacheSetRequestInterceptor(
    element: any,
    operation: string,
    what: string,
    url: string,
    headers: any,
    params: any,
    httpConfig: angular.IRequestShortcutConfig,
  ): void {
    // If request cache is globally disabled, return
    if (!this.appConfig.requestCache) {
      return;
    }
    /*
     * only cache (or fetch from cache) if resource should use cache
     * and the operation is getList
     */
    if (!['getList', 'get'].includes(operation) || !this.useCache()) {
      return;
    }
    // use cache, if the data is cached then the request would be canceled
    httpConfig.cache = this.getCacheFactory();
  }

  /**
   * Destroys the cache when POST/PUT/PATCH/DELETE operations are made.
   *
   * @param {object} element - The element to send to the server.
   * @param {string} operation - The operation made
   */
  cacheClearRequestInterceptor(element: any, operation: string) {
    const cacheDestroyOp = ['post', 'put', 'patch', 'delete', 'remove'];

    if (!cacheDestroyOp.includes(operation)) {
      return;
    }
    // server has been updated, destroy the cache
    this.cacheEmpty();
  }

  /**
   * Should be overridden by derived class. Return false if
   * want to use mock server
   *
   * @returns {boolean}
   */
  useMock(): boolean {
    return false;
  }

  /**
   * Should be overridden by derived class. Return false if
   * want to cache the data
   *
   * @returns {boolean}
   */
  useCache(): boolean {
    return false;
  }

  get cacheId(): string {
    return `${this.resource}Cache`;
  }

  /**
   * Insert the item with the given key and value into the cache.
   * @param {string} key
   * @param {*} value
   * @param {object|number} [cacheOptions] - if a number is passed, it will be used as 'expires'
   */
  cachePut(key: string, value, cacheOptions?): void {
    let opts = cacheOptions;
    // if a number is passed, use it as the cache's expiry
    if (typeof cacheOptions === 'number') {
      opts = { expires: cacheOptions };
    }
    this.getCacheFactory().put(key, value, opts);
  }

  /**
   * Remove and return the item with the given key, if it is in the cache.
   * @param {string} key
   */
  cacheRemove(key: string): void {
    this.getCacheFactory().remove(key);
  }

  /**
   * Remove all the keys from the cache
   */
  cacheEmpty(): void {
    this.getCacheFactory().removeAll();
  }

  /**
   * Returns cached data for the given cache key.
   *
   * It first gets the cache factory for this resource,
   * then tries to get the wanted cache key.
   * If the cache key does not exist, it returns undefined.
   *
   * @param {string} cacheKey
   * @returns {*}
   */
  getCached(cacheKey: string): any {
    return this.getCacheFactory().get(cacheKey);
  }

  /**
   * Return cache object if already exists otherwise creates a new Cache object
   * @returns {Cache}
   */
  getCacheFactory(): CacheFactory.ICache {
    let cache = this.CacheFactory.get(this.cacheId);

    if (_.isUndefined(cache)) {
      // create cache with the default options from cache config file
      cache = this.CacheFactory(this.cacheId, this.cacheOptions);
    }

    return cache;
  }

  get cacheOptions(): CacheOptions {
    return {};
  }

  /**
   * Add filter params to query params
   *
   * Property param can be either a property name and value, or a key-value object
   *
   * @example (simple)
   * .filter('statusCode', 'new')
   * .filter('isActive', true)
   * .filter('countryId', [1, 2, 5])
   * .filter('totalDeposit', { gte: 0, lte: 100 }
   *
   * @example (object notation)
   * .filter({
   *   statusCode: "new",
   *   isActive: true,
   *   countryId: [1, 2, 5]
   *   totalDeposit: {
   *     "gte": 0,
   *     "lte": 100
   *   }
   * })
   *
   * @param {string|object} propertyOrFilters - property name or key-value object
   * @param {any} [value] - property value (when property is a string)
   * @returns {this}
   */
  filter(
    propertyOrFilters: string | { [key: string]: any },
    value?: any,
  ): this {
    const permittedMethods = ['lte', 'gte', 'exclude'];

    /**
     * Add basic filter to query params
     * @param {string} prop - filter property name
     * @param {PrimitiveValue | PrimitiveValue[]} val - filter value
     * @return {this} - RestService instance
     */
    const basicFilter = (
      prop: string,
      val: PrimitiveValue | PrimitiveValue[],
    ) => {
      let propName = prop;
      if (_.isArray(val)) {
        // the PHP backend expects arrays to be suffixed by "[]"
        propName = `${prop}[]`;
      }

      this.addQueryParams(propName, val);
      return this;
    };

    // returns true if the filter is an object which contains an operator (lte/gte/exclude)
    const hasOperator = (filter) =>
      _.isObject(filter) &&
      _.intersection(Object.keys(filter), permittedMethods).length > 0;

    if (_.isString(propertyOrFilters)) {
      return basicFilter(propertyOrFilters, value);
    }
    // object which contains multiple filters
    _.each(propertyOrFilters, (filterValue, prop: string) => {
      if (hasOperator(filterValue)) {
        // iterate over filter methods
        _.each(filterValue, (val, filterMethod: string) => {
          // add filter params to query
          this[filterMethod].call(this, prop, val);
        });
      } else {
        basicFilter(prop, filterValue);
      }
    });

    return this;
  }

  /**
   * Exclude elements by condition
   *
   * @param {String} property
   * @param {string|number} value
   * @returns {this}
   */
  exclude(property: string, value: string | number): this {
    return this.addQueryOperator(property, value, OPERATOR_EXCLUDE);
  }

  /**
   * Apply lte condition to given property
   *
   * @param {String} property
   * @param {string|number} value
   * @returns {RestService}
   */
  lte(property: string, value: string | number) {
    return this.addQueryOperator(property, value, OPERATOR_LTE);
  }

  /**
   * Apply gte condition to given property
   *
   * @param {String} property
   * @param {string|number} value
   * @returns {RestService}
   */
  gte(property: string, value: string | number) {
    return this.addQueryOperator(property, value, OPERATOR_GTE);
  }

  /**
   * Preforms full text search
   *
   * @param {string|number} value
   */
  search(value: string | number): this {
    this.addQueryParams(SEARCH, value);

    return this;
  }

  /**
   * Adds condition to query on given property
   *
   * @param {String} property
   * @param {string|number} value
   * @param {String} operator
   */
  addQueryOperator(
    property: string,
    value: string | number,
    operator: string,
  ): this {
    let propertyAffectedName = property + operator;

    if (_.isArray(value)) {
      // the PHP backend expects arrays to be suffixed by "[]"
      propertyAffectedName = `${propertyAffectedName}[]`;
    }

    this.addQueryParams(propertyAffectedName, value);

    return this;
  }

  /**
   * Add _start and _end or _limit to queryParams
   * (an X-Total-Count header should be included in the response and would
   * set to total property)
   *
   * @param {Number} start
   * @param {Number} end
   * @param {Number} limit
   * @returns {RestService}
   */
  slice(start: number, end: number, limit: number): this {
    const sliceParams = _.object(
      [SLICE_START, SLICE_END, SLICE_LIMIT],
      [start, end, limit],
    );

    this.addQueryParams(sliceParams);

    return this;
  }

  /**
   * Add sort by property to query params. By default sort
   * will be by ASC direction
   *
   * Example of usage -
   *
   * 1. sort("firstName", "desc")
   * 2. sort({firstName: "desc"})
   *
   * In both ways the output will be the same
   *
   * @param {String/Object} property
   * @param {String} [direction] - optional, if undefined sort will be preformed in ASC direction
   * @returns {*}
   */
  sort(property: string | SortObject, direction?: SortDirection): this {
    let propName: string;
    let sortDir: string;

    if (_.isEmpty(property)) {
      return this;
    }

    if (typeof property === 'string') {
      propName = property;
      sortDir = direction;
    } else if (typeof property === 'object') {
      [propName, sortDir] = _.pairs(property)[0];
    }

    this.addQueryParams({
      [SORT]: propName,
      [ORDER]: sortDir.toUpperCase(),
    });

    return this;
  }

  /**
   *  To include parent resource, add _expand
   *
   * @param {string|string[]} resource
   * @returns {*}
   */
  expand(resource: string | string[]): this {
    return this.relationship('expand', resource);
  }

  /**
   * To include children resources, add _embed
   *
   * @param {string|string[]} resource
   * @returns {*}
   */
  embed(resource: string | string[]): this {
    return this.relationship('embed', resource);
  }

  /**
   * Combine related resources to query
   *
   * @param {string} operation
   * @param {string|string[]} resource
   * @returns {*}
   * @private
   */
  private relationship(operation: string, resource: string | string[]): this {
    let resultResource = resource;
    const map = {
      expand: OPERATOR_EXPAND,
      embed: OPERATOR_EMBED,
    };

    if (typeof resultResource === 'string') {
      resultResource = [resultResource];
    }

    this.addQueryParams(map[operation], resultResource);

    return this;
  }

  /**
   * Add params to query params object.
   *
   * @param {Object|string} params
   * @param {any} [value] - used as value when 'params' is a string
   * @returns {Object} - the merged query params object
   */
  addQueryParams(params: string | { [key: string]: any }, value?: any) {
    const paramsObject = _.isString(params) ? { [params]: value } : params;

    /*
     * remove properties with undefined values
     * and add them to query params
     */
    return Object.assign(this.queryParams, _.omit(paramsObject, _.isUndefined));
  }

  /**
   * Update object properties on collection.
   * Implement a mechanism to support several insertions on a same patch method.
   *
   *  This will create a new Restangular object that is just a pointer to one
   *  element, which will be used for patch collection or object(inside the http
   *  message body)
   * todoOld: remove this method and replace usages with patchElement
   * @param {string} selector
   * @param {object} obj
   * @returns {*}
   */
  patchCollection<T extends Restangular.IElement>(
    selector: string,
    obj: any,
  ): Promise<T> {
    return this.resourceBuildStart()
      .getElement(selector)
      .resourceBuildEnd()
      .patchWithQuery(obj);
  }

  /**
   * Method to simply patch an element
   * @param {number|string} elementId - Element id to patch
   * @param {object} data - data to patch
   * @return {Promise} - Restangularized promise of the server result
   */
  patchElement<T extends Restangular.IElement>(
    elementId: number | string,
    data: any,
  ): Promise<T> {
    return this.resourceBuildStart()
      .getElement(elementId)
      .resourceBuildEnd()
      .patchWithQuery(data);
  }

  /**
   * Method to simply remove an element
   * @param {number|string} elementId - Element id to remove
   * @return {Promise} - Restangularized promise of the server result
   * @throws {Error} - when element does not have an id field
   */
  removeElement(elementId: number | string): Promise<any> {
    return this.resourceBuildStart()
      .getElement(elementId)
      .resourceBuildEnd()
      .removeWithQuery();
  }

  /**
   * REST POST with query, the "post" method that is in use here is a collection method
   *
   * @param {object} element
   */
  postWithQuery<T extends Restangular.IElement>(element): Promise<T> {
    return this.insertUpdateWithQuery('post', element);
  }

  /**
   * REST PATCH with query, the "PATCH" method that is in use here is a collection method
   *
   * @param {object} element
   */
  patchWithQuery<T extends Restangular.IElement>(element: any): Promise<T> {
    return this.insertUpdateWithQuery('patch', element);
  }

  /**
   * Rest custom PUT. you should use it when you want to perform a batch collection update on an element.
   * using customPut will let you escape restangular put format which enforce id property in your collection by default.
   *
   * @param {object} element
   */
  customPutWithQuery(element): Promise<any> {
    const response = this.restService.customPUT(element);

    this.resetQuery();
    return response;
  }

  customGetWithQuery<T = any>(): Promise<T> {
    const response = this.restService.customGET();

    this.resetQuery();
    return response;
  }

  customGetWithConfig<T = any>(config): Promise<T> {
    const response = this.restService.withHttpConfig(config).customGET();

    this.resetQuery();
    return response;
  }

  /**
   * Use this when you need to GET from a non json api standard route.
   * Using getWithQuery on a non json api standard, adds the string [object%20Object] to the GET request url,
   * which automatically fails the GET request.
   * This allows you to provide a custom and completely freeform url.
   * @param path the custom (sub)path you want to GET from.
   * @param config httpConfig of restangular.
   */
  getWithCustomPath(path: string, config = {}) {
    return this.restService.withHttpConfig(config).customGET(path);
  }

  customGetWithHeaders(path: string, queryString: object, headers: object) {
    return this.restService
      .withHttpConfig({})
      .customGET(path, queryString, headers);
  }

  /**
   * Rest custom Post.
   *
   * @param {object} element
   */
  customPostWithQuery(element): Promise<any> {
    const response = this.restService.customPOST(element);

    this.resetQuery();
    return response;
  }

  customPostWithHeaders(
    element: any,
    path: string,
    queryString: object,
    headers: object,
  ): Promise<any> {
    return this.restService
      .withHttpConfig({})
      .customPOST(element, path, queryString, headers);
  }

  /**
   * Removes element. Path should be configured before using this method
   *
   * @returns {*}
   */
  removeWithQuery(): Promise<any> {
    const response = this.restService.remove(this.queryParams);

    this.resetQuery();
    return response;
  }

  /**
   *  REST UPDATE with type and query, the "UPDATE" method that is in use here is a collection method
   *
   * @param {string} method
   * @param {object} element
   * @returns {*}
   * @private
   */
  private insertUpdateWithQuery<T extends Restangular.IElement>(
    method: string,
    element: any,
  ): Promise<T> {
    const response = this.restService[method](element, this.queryParams);
    /*
     * because the service is singleton, the query params
     * must be re-initialized after preforming the request
     */
    this.resetQuery();
    return response;
  }

  /**
   * REST GET with query
   *
   * @returns {*|{method, params, headers}}
   */
  getListWithQuery<T extends Restangular.IElement>(): ICollectionPromiseRestNg<
    T
  > {
    const response = this.restService.getList(this.queryParams);
    /*
     * because the service is singleton, the query params
     * must be re-initialized after preforming the request
     */
    this.resetQuery();
    return response;
  }

  /**
   * GET one entity with query
   *
   * @param {string|number} [id] - id to fetch
   *
   * @return {Restangular}
   */
  getOneWithQuery<T extends Restangular.IElement>(
    id?: string | number,
  ): Promise<T> {
    const response = id
      ? this.restService.one(id).get(this.queryParams)
      : this.restService.get(this.queryParams);
    /*
     * because the service is singleton, the query params
     * must be re-initialized after preforming the request
     */
    this.resetQuery();
    return response;
  }

  removeItem(): Promise<any> {
    const response = this.restService.remove();

    this.resetQuery();
    return response;
  }

  /**
   * Reset query params and restores original restangular service, if exists.
   *
   * @returns {this}
   */
  resetQuery(): this {
    if (this.originalService) {
      this.restService = this.originalService;
    }
    this.queryParams = {};

    return this;
  }

  /**
   * Modify queryParams in a way that collection of
   * elements that are related to given page would be returned.
   *
   * @param {Number} page - number of page to fetch
   * @param {Number} count - number of elements per page
   */
  setPage(page: number, count: number) {
    const start = (page - 1) * count;
    const limit = count;

    return this.slice(start, undefined, limit);
  }

  /**
   * Starts a new Restangular resource building process.
   * Initializes the restangularResource to the current active rest service
   *
   * @returns {this}
   */
  resourceBuildStart(): this {
    this.restangularResource = this.restService;
    return this;
  }

  /**
   * Ends the Restangular build process by overriding the active rest service
   * by the Restangular resource we built. (backing up the original service first)
   * The original service will be restored by the restQuery method.
   *
   * Finally, resets the restangularResource property.
   * @returns {this}
   */
  resourceBuildEnd(): this {
    // Backup the original service
    this.originalService = this.restService;
    // override the active service with the resource we built
    this.restService = this.restangularResource;
    // reset property
    this.restangularResource = null;
    return this;
  }

  /**
   * Part of the Restangular resource build chain:
   *
   * Chains the id specific by 'id' to the resource we built.
   * This method can only be used when we first start to build the resource.
   * (when using a nested element, use getNestedElement instead)
   *
   * e.g. when the resource we built so far is "customers/", calling this method with id=1
   * will cause the new resource to be "customers/1".
   * @param {number|string} id
   * @returns {this}
   */
  getElement(id): this {
    this.restangularResource = this.restangularResource.one(id);
    return this;
  }

  /**
   * Part of the Restangular resource build chain:
   * Chains a nested collection specified by the passed route parameter to the resource.
   *
   * After calling this method, only list methods (e.g. getListWithQuery) can be called.
   *
   * e.g. when the resource we built so far is "customers/1", calling this method with route="calls"
   * will cause the new resource to be "customers/1/calls".
   *
   * @param {string} route
   * @returns {RestService}
   */
  getNestedCollection(route): this {
    this.restangularResource = this.restangularResource.all(route);
    return this;
  }

  /**
   * Part of the Restangular resource build chain:
   * Chains a nested element specified by the passed route and id parameters to the resource.
   *
   * This method should only be called on a specific element (e.g customer/1) and NOT on a collection.
   *
   * e.g. when the resource we built so far is "customers/1", calling this method with
   * route="calls", id=2 will cause the new resource to be "customers/1/calls/2".
   * @param {string} route
   * @param {number} id
   * @returns {RestService}
   */
  getNestedElement(route, id) {
    this.restangularResource = this.restangularResource.one(route, id);
    return this;
  }

  /**
   * Return rest service
   *
   * @returns {Restangular}
   */
  get rest() {
    return this.restService;
  }

  /**
   * Return response headers
   *
   * @returns {Object}
   */
  get headers() {
    return this.lastResponseHeaders || {};
  }

  /**
   * Set response headers
   *
   * @param {Object} headers
   */
  set headers(headers) {
    this.lastResponseHeaders = headers;
  }

  /**
   * Return total number of collection.
   * Filled only when GET method used with filter.
   * Useful for paging, returns null if undefined
   *
   * @returns {Number|undefined}
   */
  get total(): number {
    /*
     * for some reason this header returned as a string from server
     * so, we will parse int
     */
    return parseInt(this.headers[TOTAL_ELEMENTS_HEADER], 10);
  }
}

export default RestService;
