import SocketService from '~/source/common/services/socket';

export class ChallengeSocketService extends SocketService {
  get channelRoot() {
    return 'CustomerChallenge';
  }

  get whiteList() {
    return ['status'];
  }
}

export default ChallengeSocketService;
