import {
  PlatformCode,
  reachedMarginLevel,
} from '@proftit/crm.api.models.enums';
import { TotalsTile } from '~/source/contact/contact-page/trading-account/common/totals-tile';

export const propAccountTotalsConfig: TotalsTile[] = [
  {
    primary: {
      title: 'contact.tradingAccount.BALANCE',
      field: 'balance',
      type: 'monetary',
      fractionSize: 2,
    },
  },
  {
    primary: {
      title: 'contact.tradingAccount.MARGIN',
      field: 'margin',
      type: 'monetary',
      fractionSize: 2,
    },
    secondary: {
      title: 'contact.tradingAccount.MARGIN_LEVEL',
      field: 'marginLevel',
      type: 'percentage',
      reachMarginIndicator: (account) =>
        reachedMarginLevel.includes(account.marginLevelType),
    },
    condition: (account) => account.platform.code !== PlatformCode.Bundle,
  },
  {
    primary: {
      title: 'contact.tradingAccount.PURCHASES',
      field: 'deposit',
      type: 'monetary',
      fractionSize: 2,
    },
    secondary: {
      title: 'contact.tradingAccount.NET',
      field: 'netDeposit',
      type: 'monetary',
    },
    condition: (account) => account.type !== 'prop',
  },
  {
    primary: {
      title: 'contact.tradingAccount.CREDIT',
      field: 'credit',
      type: 'monetary',
    },
  },
  {
    primary: {
      title: 'contact.tradingAccount.WITHDRAWALS_PROP',
      field: 'withdrawal',
      type: 'monetary',
      fractionSize: 2,
    },
  },
  {
    primary: {
      title: 'contact.tradingAccount.INTERNAL_TRANSFER',
      field: 'internalTransfer',
      type: 'monetary',
      fractionSize: 2,
    },
    condition: (account) => account.type !== 'prop',
  },
  {
    primary: {
      title: 'contact.tradingAccount.EQUITY',
      field: 'equity',
      type: 'monetary',
      fractionSize: 2,
      reachMarginIndicator: (account) =>
        reachedMarginLevel.includes(account.marginLevelType),
    },
    secondary: {
      title: 'contact.tradingAccount.FREE_MARGIN',
      field: 'freeMargin',
      type: 'monetary',
      fractionSize: 2,
      condition: (account) => account.platform.code !== PlatformCode.Bundle,
    },
  },
  {
    primary: {
      title: 'contact.tradingAccount.VOLUME',
      field: 'turnover',
      type: 'monetary',
    },
    secondary: {
      title: 'contact.tradingAccount.OPEN_VOLUME',
      field: 'openVolume',
      type: 'monetary',
    },
  },
  {
    primary: {
      title: 'contact.tradingAccount.TRADING_PL',
      default: 'N/A',
      field: 'pnl',
      type: 'oppositeMonetary',
      fractionSize: 2,
    },
    secondary: {
      title: 'contact.tradingAccount.OPEN_PNL',
      field: '_openPnl',
      type: 'monetary',
    },
  },
  {
    primary: {
      title: 'contact.tradingAccount.REAL_PL',
      field: 'cashPnl',
      type: 'oppositeMonetary',
      fractionSize: 2,
    },
  },
  {
    primary: {
      title: 'contact.tradingAccount.WITHDRAWABLE_BALANCE_PROP',
      field: 'withdrawable',
      type: 'monetary',
      fractionSize: 2,
    },
  },
  {
    primary: {
      title: 'contact.tradingAccount.COMMISSIONS',
      field: 'commission',
      type: 'monetary',
    },
    secondary: {
      title: 'contact.tradingAccount.SWAP',
      field: 'swap',
      type: 'monetary',
    },
    condition: (account) => account.platform.code !== PlatformCode.Bundle,
  },
  {
    primary: {
      title: 'contact.tradingAccount.BROKER_FEE',
      field: 'brokerFee',
      type: 'monetary',
    },
    secondary: {
      title: 'contact.tradingAccount.FEES',
      field: 'fee',
      type: 'monetary',
    },
  },
];
