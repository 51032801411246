import SocketService from '~/source/common/services/socket';

export class CustomerChallengePhaseSocketService extends SocketService {
  get channelRoot() {
    return 'CustomerChallengePhase';
  }

  get whiteList() {
    return [
      'id',
      'name',
      'description',
      'fields',
      'isFunded',
      'order',
      'startedAt',
      'status',
    ];
  }
}

export default CustomerChallengePhaseSocketService;
