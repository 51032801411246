export const challengesReportsSettings = {
  table: {
    ngTable: {
      parameters: {
        count: 50,
        page: 1,
        sorting: {
          transactionDate: 'desc',
        },
      },
      settings: {
        counts: [],
      },
    },
    quickFilters_comment:
      'if quick filter is active by default. you must set its value in bgTable.parameters.filter above',
    quickFilters: {
      reportsRealContacts: {
        state: {
          isActive: true,
        },
      },
    },
    colsList: [
      {
        title: 'ID',
        field: 'id',
        fieldName: 'id',
        fieldFormat: '{{ id }}',
        reportFields: ['id'],
        filterable: false,
        sortable: 'id',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'CUSTOMER_ID',
        field: 'customerId',
        fieldName: 'customerId',
        fieldFormat: '{{ customerId }}',
        reportFields: ['customerId'],
        filterable: false,
        sortable: 'customerId',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'CUSTOMER_NAME',
        field: 'customerName',
        fieldName: 'customerName',
        fieldFormat: '{{ customerName }}',
        reportFields: ['customerName'],
        filterable: false,
        sortable: 'customerName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'STARTING_DATE',
        field: 'startingDate',
        fieldName: 'startingDate',
        fieldFormat: '{{ startingDate }}',
        reportFields: ['startingDate'],
        filterable: true,
        sortable: 'startingDate',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'CHALLENGE_NAME',
        field: 'challengeName',
        fieldName: 'challengeName',
        fieldFormat: '{{ challengeName }}',
        reportFields: ['challengeName'],
        filterable: false,
        sortable: 'challengeName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'PHASE_NAME',
        field: 'phaseName',
        fieldName: 'phaseName',
        fieldFormat: '{{ phaseName }}',
        reportFields: ['phaseName'],
        filterable: false,
        sortable: 'phaseName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'PHASE_ORDER',
        field: 'phaseOrder',
        fieldName: 'phaseOrder',
        fieldFormat: '{{ phaseOrder }}',
        reportFields: ['phaseOrder'],
        filterable: false,
        sortable: 'phaseOrder',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'STATUS',
        field: 'status',
        fieldName: 'status',
        fieldFormat: '{{ status }}',
        reportFields: ['status'],
        filterable: false,
        sortable: 'status',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'STARTING_BALANCE',
        field: 'startingBalance',
        fieldName: 'startingBalance',
        fieldFormat: '{{ startingBalance }}',
        reportFields: ['startingBalance'],
        filterable: false,
        sortable: 'startingBalance',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'CURRENT_BALANCE',
        field: 'currentBalance',
        fieldName: 'currentBalance',
        fieldFormat: '{{ currentBalance }}',
        reportFields: ['currentBalance'],
        filterable: false,
        sortable: 'currentBalance',
        removable: true,
        show: true,
      },
      {
        title: 'PROFIT_TARGET',
        field: 'profitTarget',
        fieldName: 'profitTarget',
        fieldFormat: '{{ profitTarget }}',
        reportFields: ['profitTarget'],
        filterable: false,
        sortable: 'profitTarget',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'MAX_LOSS',
        field: 'maxLoss',
        fieldName: 'maxLoss',
        fieldFormat: '{{ maxLoss }}',
        reportFields: ['maxLoss'],
        filterable: false,
        sortable: 'maxLoss',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'MAX_DAILY_LOSS',
        field: 'maxDailyLoss',
        fieldName: 'maxDailyLoss',
        fieldFormat: '{{ maxDailyLoss }}',
        reportFields: ['maxDailyLoss'],
        filterable: false,
        sortable: 'maxDailyLoss',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'MIN_TRADING_DAYS',
        field: 'minTradingDays',
        fieldName: 'minTradingDays',
        fieldFormat: '{{ minTradingDays }}',
        reportFields: ['minTradingDays'],
        filterable: false,
        sortable: 'minTradingDays',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'MAX_TRADING_DAYS',
        field: 'maxTradingDays',
        fieldName: 'maxTradingDays',
        fieldFormat: '{{ maxTradingDays }}',
        reportFields: ['maxTradingDays'],
        filterable: false,
        sortable: 'maxTradingDays',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'CURRENT_PROFIT',
        field: 'currentProfit',
        fieldName: 'currentProfit',
        fieldFormat: '{{ currentProfit | number : 2 }}',
        reportFields: ['currentProfit'],
        filterable: false,
        sortable: 'currentProfit',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'PROFIT_STRUCTURE',
        field: 'profitStructure',
        fieldName: 'profitStructure',
        fieldFormat: '{{ profitStructure }}',
        reportFields: ['profitStructure'],
        filterable: false,
        sortable: 'profitStructure',
        removable: true,
        show: true,
        type: 'common',
      },
    ],
  },
  assignUsersLimit: 10000,
  userEvents: {
    toggleItem: 'toggleItem',
    paging: 'paging',
  },
};
