import { challengesReportsSettings } from './challenges-report-settings';
const USER_SETTING_REPORTS_COLUMNS_KEY = 'challengessReportsTableColumnsV2';
import template from '../../common-reports-table.component.html';
import { CommonReportsTableController } from '../../common-reports-table.component';

export class challengesReportTableController extends CommonReportsTableController {
  resource: string = 'challenges';
  exportResource: string = 'challenges';

  /* @ngInject */
  constructor(...args) {
    super(
      <any>challengesReportsSettings,
      USER_SETTING_REPORTS_COLUMNS_KEY,
      'challengesReportTable',
      args,
    );
  }

  get tableKey() {
    // for save the user setting we need to add it to the server const,
    // this will error now...
    return 'challenges_report_table';
  }
}

export const ChallengesReportTableComponent = {
  bindings: {
    brand: '<',
  },
  template,
  controllerAs: 'vm',
  controller: challengesReportTableController,
};
