import { Permissions } from '~/source/common/models/permission-structure';

export const failedDepositsReportsSettings = {
  table: {
    ngTable: {
      parameters: {
        count: 50,
        page: 1,
        sorting: {
          requestedAt: 'desc',
        },
      },
      settings: {
        counts: [],
      },
    },
    quickFilters_comment:
      'if quick filter is active by default. you must set its value in bgTable.parameters.filter above',
    quickFilters: {
      reportsRealContacts: {
        state: {
          isActive: true,
        },
      },
    },
    colsList: [
      {
        title: 'contactsImportTable.fields.CREATED_AT',
        field: 'createdAt',
        fieldName: 'createdAt',
        fieldFormat: '{{ createdAt }}',
        reportFields: ['createdAt'],
        filterable: true,
        sortable: 'createdAt',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.REQUESTED_DATE',
        field: 'requestedAt',
        fieldName: 'requestedAt',
        fieldFormat: '{{ requestedAt }}',
        reportFields: ['requestedAt'],
        filterable: true,
        sortable: 'requestedAt',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'CUSTOMER_ID',
        field: 'customerId',
        fieldName: 'customerId',
        fieldFormat: '{{ customerId }}',
        reportFields: ['customerId'],
        filterable: false,
        sortable: 'customerId',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'CUSTOMER_NAME',
        field: 'customerName',
        fieldName: 'customerName',
        fieldFormat: '{{ customerName }}',
        reportFields: ['customerName'],
        filterable: false,
        sortable: 'customerName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.ACCOUNT_NAME',
        field: 'accountName',
        fieldName: 'accountName',
        fieldFormat: '{{ accountName }}',
        reportFields: ['accountName'],
        filterable: false,
        sortable: 'accountName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'portfolio.table.TRADING_ACCOUNT_ID',
        field: 'tradingAccountId',
        fieldName: 'tradingAccountId',
        fieldFormat: '{{ tradingAccountId }}',
        reportFields: ['tradingAccountId'],
        filterable: false,
        sortable: 'tradingAccountId',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.AMOUNT',
        field: 'amount',
        fieldName: 'amount',
        fieldFormat: '{{ amount | number : 2 }}',
        reportFields: ['amount'],
        filterable: true,
        sortable: 'amount',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.AMOUNT_BASIC',
        field: 'amountBasic',
        fieldName: 'amountBasic',
        fieldFormat: '{{ amountBasic | baseCurrencyFormat : 2 : true}}',
        reportFields: ['amountBasic'],
        filterable: true,
        sortable: 'amountBasic',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.CURRENCY',
        field: 'currency',
        fieldName: 'currency',
        fieldFormat: '{{ currency }}',
        reportFields: ['currency'],
        filterable: false,
        sortable: 'currency',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.FOUR_LAST_DIGITS',
        field: 'fourLastDigits',
        fieldName: 'fourLastDigits',
        fieldFormat: '{{ fourLastDigits }}',
        reportFields: ['fourLastDigits'],
        filterable: false,
        sortable: 'fourLastDigits',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'CLEARING_COMPANY',
        field: 'reportsClearingCompany',
        fieldName: 'clearingCompany',
        fieldFormat: '{{ clearingCompany }}',
        reportFields: ['clearingCompany'],
        filterable: true,
        sortable: 'clearingCompany',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.REJECTION_CODE',
        field: 'rejectionCode',
        fieldName: 'rejectionCode',
        fieldFormat: '{{ rejectionCode }}',
        reportFields: ['rejectionCode'],
        filterable: false,
        sortable: 'rejectionCode',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.REJECTION_REASON',
        field: 'rejectionReason',
        fieldName: 'rejectionReason',
        fieldFormat: '{{ rejectionReason }}',
        reportFields: ['rejectionReason'],
        filterable: false,
        sortable: 'rejectionReason',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.STATUS',
        field: 'transactionStatusCode',
        fieldName: 'status',
        fieldFormat: '{{ status }}',
        reportFields: ['status'],
        filterable: true,
        sortable: 'status',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.METHOD',
        field: 'reportsTransferMethodType',
        fieldName: 'method',
        fieldFormat: '{{ method }}',
        reportFields: ['method'],
        filterable: true,
        sortable: 'method',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.NOTE',
        field: 'note',
        fieldName: 'note',
        fieldFormat: '{{ note }}',
        reportFields: ['note'],
        filterable: false,
        sortable: 'note',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.ACCOUNT_MANAGER',
        field: 'userFullname',
        fieldName: 'userFullname',
        fieldFormat: '{{ userFullname }}',
        reportFields: ['userFullname'],
        filterable: true,
        sortable: 'userFullname',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.OWNER',
        field: 'reportsOwner',
        fieldName: 'owner',
        fieldFormat: '{{ owner }}',
        reportFields: ['owner'],
        filterable: true,
        sortable: 'owner',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'deposits.OWNER_DESK',
        field: 'ownerDesk',
        fieldName: 'ownerDesk',
        fieldFormat: '{{ ownerDesk }}',
        reportFields: ['ownerDesk'],
        filterable: true,
        sortable: 'ownerDesk',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.CHALLENGE_NAME',
        field: 'challengeName',
        fieldName: 'challengeName',
        fieldFormat: '{{ challengeName }}',
        reportFields: ['challengeName'],
        filterable: false,
        sortable: 'challengeName',
        removable: true,
        show: true,
        type: 'prop',
      },
      {
        title: 'contact.dashboard.titles.OFFER_NAME',
        field: 'offerName',
        fieldName: 'offerName',
        fieldFormat: '{{ offerName }}',
        reportFields: ['offerName'],
        filterable: false,
        sortable: 'offerName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.CAMPAIGN',
        field: 'reportsCampaign',
        fieldName: 'campaign',
        fieldFormat: '{{ campaign }}',
        reportFields: ['campaign'],
        filterable: true,
        sortable: 'campaign',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.DESK',
        field: 'reportsDesk',
        fieldName: 'reportsDesk',
        fieldFormat: '{{ desk }}',
        reportFields: ['desk'],
        filterable: true,
        sortable: 'desk',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reporrts.titles.PROCESSING_EMPLOYEE',
        field: 'reportsByEmployee',
        fieldName: 'employee',
        fieldFormat: '{{ employee }}',
        reportFields: ['employee'],
        filterable: true,
        sortable: 'employee',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'BRAND',
        field: 'reportsBrand',
        fieldName: 'reportsBrand',
        fieldFormat: '{{ brand }}',
        reportFields: ['brand'],
        filterable: true,
        sortable: 'brand',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'COUNTRY',
        field: 'reportsCountry',
        fieldName: 'country',
        fieldFormat: '{{ country }}',
        reportFields: ['country'],
        filterable: true,
        sortable: 'country',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.VERIFIED_STATUS',
        field: 'reportsCustomerComplianceName',
        fieldName: 'customerComplianceName',
        fieldFormat: '{{ customerComplianceName }}',
        reportFields: ['customerComplianceName'],
        filterable: true,
        sortable: 'customerComplianceName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.REAL',
        field: 'reportsReal',
        fieldName: 'real',
        fieldFormat: '{{ real }}',
        reportFields: ['real'],
        filterable: true,
        sortable: 'real',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.AMOUNT_WITHDRAWABLE',
        field: 'amountWithdrawable',
        fieldName: 'amountWithdrawable',
        fieldFormat: '{{ amountWithdrawable | number : 2 }}',
        reportFields: ['amountWithdrawable'],
        filterable: true,
        sortable: 'amountWithdrawable',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'DEPARTMENT',
        field: 'reportsDepartment',
        fieldName: 'department',
        fieldFormat: '{{ department }}',
        reportFields: ['department'],
        filterable: true,
        sortable: 'department',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'common.EXTERNAL_ID',
        field: 'externalId',
        fieldName: 'externalId',
        fieldFormat: '{{ externalId }}',
        reportFields: ['externalId'],
        filterable: false,
        sortable: 'externalId',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'common.LANGUAGE',
        field: 'reportsLanguage',
        fieldName: 'language',
        fieldFormat: '{{ language }}',
        reportFields: ['language'],
        filterable: true,
        sortable: 'language',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'LAST_UPDATED_DATE',
        field: 'updatedAt',
        fieldName: 'updatedAt',
        fieldFormat: '{{ updatedAt }}',
        reportFields: ['updatedAt'],
        filterable: true,
        sortable: 'updatedAt',
        removable: true,
        show: false,
        type: 'common',
      },
    ],
  },
  assignUsersLimit: 10000,
  userEvents: {
    toggleItem: 'toggleItem',
    paging: 'paging',
  },
};
