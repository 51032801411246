import s from 'underscore.string';
import TableLiveController from '~/source/common/components/table/table-live.controller';
import CustomersService from '~/source/contact/common/services/customers';
import PopupService from '~/source/common/components/modal/popup.service';
import { ICollection } from 'restangular';
import tablePopupTpl from '~/source/common/components/table/table-popup.html';
import * as _ from '@proftit/lodash';
import { Mt4OrderPnlSocketService } from '~/source/common/services/mt4-order-pnl-socket.service';
import { BrandsService } from '~/source/management/brand/services/brands';
import { FeaturesFlagsService } from '~/source/common/services/features-flags.service';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { useStreams } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';
import { CfdPlatformPositionPayoutSocketService } from '~/source/common/api-cfd-platform/cfd-platform-position-payout-socket.service';
import { streamSubscribeToPositionSocketsUpdates } from './utils/stream-subscribe-to-position-sockets-updates';
import { TradingAccount, Customer } from '@proftit/crm.api.models.entities';
import { CurrentPlatformSessionStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-session-store-service.directive';

const OPEN_PNL_UPDATE_INTERVAL = 5000;

abstract class Controller extends TableLiveController {
  static $inject = [
    'popupService',
    'customersService',
    'mt4OrderPnlSocketService',
    'brandsService',
    'featuresFlags',
    'cfdPlatformPositionPayoutSocketService',
    ...TableLiveController.$inject,
  ];

  /* require */

  prfCurrentPlatformSession: CurrentPlatformSessionStoreServiceDirectiveController;

  /* state */

  lifecycles = observeComponentLifecycles(this);

  // Services
  customersService: () => CustomersService;
  popupService: PopupService;
  mt4OrderPnlSocketService: () => Mt4OrderPnlSocketService;
  cfdPlatformPositionPayoutSocketService: () => CfdPlatformPositionPayoutSocketService;
  brandsService: () => BrandsService;
  featuresFlags: FeaturesFlagsService;

  // from popup data
  positions: ICollection;
  account: TradingAccount;
  customer: Customer;

  account$ = observeShareCompChange<TradingAccount>(
    this.lifecycles.onChanges$,
    'account',
  );

  customer$ = observeShareCompChange<Customer>(
    this.lifecycles.onChanges$,
    'customer',
  );

  abstract getDataRequest();

  constructor(...args) {
    super(...args);

    useStreams([this.account$, this.customer$], this.lifecycles.onDestroy$);
  }

  $onInit() {
    super.$onInit();
    this.excludeNotifications = [];
    this.dataServiceInstance = this.customersService();

    useStreams(
      [
        streamSubscribeToPositionSocketsUpdates(
          this.collection$,
          OPEN_PNL_UPDATE_INTERVAL,
          this.mt4OrderPnlSocketService,
          this.cfdPlatformPositionPayoutSocketService,
          (props) => this.onEntityUpdate(props),
          this.prfCurrentPlatformSession,
          this.propertiesToUpdate$,
        ),
      ],
      this.lifecycles.onDestroy$,
    );

    this.initTable();
  }

  $onDestroy() {}

  /**
   * Returns true in notification directive is in use for this table
   *
   * @returns {boolean}
   */
  isUpdateNotification() {
    return true;
  }

  /**
   * Name of the variable that holds entities that should be updated live.
   *
   * @returns {string}
   */
  get liveEntitiesVarName() {
    return 'vm.positions';
  }

  /**
   * Return container of entities that is live updated
   *
   * @returns {Collection}
   */
  get entitiesContainer() {
    return this.positions;
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  fetchFn() {
    return this.getDataRequest();
  }

  /**
   * Open the "positions log" popup
   *
   * @return {void}
   */
  openPositionTablePopup(isPortfolioPage: boolean) {
    let accountType = s.capitalize(this.account.type);
    accountType = accountType === 'Prop' ? 'Forex' : accountType; //TODO: Temporary solution - final solution will be tackle on CRMPRFT-587
    this.popupService.open({
      controller: `positionsTable${accountType}PopupController`,
      template: tablePopupTpl,
      scope: this.$scope,
      data: {
        isPortfolioPage,
        account: this.account,
        customer: this.customer,
        prfCurrentPlatformSession: this.prfCurrentPlatformSession,
      },
    });
  }

  /**
   * don't show actions popup for sort and filter when mouse-over table columns
   * @override
   * @return {boolean}
   */
  showColumnActions() {
    return false;
  }

  /***
   * Is do entity highlight after socket update.
   *
   * Allow setting for inhariting components.
   *
   * @return setting.
   */
  getDoEntityHighlightOnUpdate() {
    return false;
  }
}

export default Controller;
