export const depositsWithdrawalsReportsSettings = {
  table: {
    ngTable: {
      parameters: {
        count: 50,
        page: 1,
        sorting: {
          confirmedAt: 'desc',
        },
      },
      settings: {
        counts: [],
      },
    },
    quickFilters_comment:
      'if quick filter is active by default. you must set its value in bgTable.parameters.filter above',
    quickFilters: {
      reportsRealContacts: {
        state: {
          isActive: true,
        },
      },
    },
    colsList: [
      {
        title: 'reports.titles.CONFIRMED_DATE',
        field: 'confirmedAt',
        fieldName: 'confirmedAt',
        fieldFormat: '{{ confirmedAt }}',
        reportFields: ['confirmedAt'],
        filterable: true,
        sortable: 'confirmedAt',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'CUSTOMER_ID',
        field: 'customerId',
        fieldName: 'customerId',
        fieldFormat: '{{ customerId }}',
        reportFields: ['customerId'],
        filterable: false,
        sortable: 'customerId',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'CUSTOMER_NAME',
        field: 'customerName',
        fieldName: 'customerName',
        fieldFormat: '{{ customerName }}',
        reportFields: ['customerName'],
        filterable: false,
        sortable: 'customerName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.EMAIL',
        field: 'email',
        fieldName: 'email',
        fieldFormat: '{{ email }}',
        reportFields: ['email'],
        filterable: false,
        sortable: 'email',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.AMOUNT',
        field: 'amount',
        fieldName: 'amount',
        fieldFormat: '{{ amount | number : 2 }}',
        reportFields: ['amount'],
        filterable: true,
        sortable: 'amount',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.AMOUNT_BASIC',
        field: 'amountBasic',
        fieldName: 'amountBasic',
        fieldFormat: '{{ amountBasic | baseCurrencyFormat : 2 : true }}',
        reportFields: ['amountBasic'],
        filterable: true,
        sortable: 'amountBasic',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.CURRENCY',
        field: 'currency',
        fieldName: 'currency',
        fieldFormat: '{{ currency }}',
        reportFields: ['currency'],
        filterable: false,
        sortable: 'currency',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.FOUR_LAST_DIGITS',
        field: 'fourLastDigits',
        fieldName: 'fourLastDigits',
        fieldFormat: '{{ fourLastDigits }}',
        reportFields: ['fourLastDigits'],
        filterable: false,
        sortable: 'fourLastDigits',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.METHOD',
        field: 'reportsTransferMethodType',
        fieldName: 'method',
        fieldFormat: '{{ method }}',
        reportFields: ['method'],
        filterable: true,
        sortable: 'method',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.PLATFORM',
        field: 'platform',
        fieldName: 'platform',
        fieldFormat: '{{ platform }}',
        reportFields: ['platform'],
        filterable: false,
        sortable: 'platform',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.ACCOUNT_MANAGER',
        field: 'userFullname',
        fieldName: 'userFullname',
        fieldFormat: '{{ userFullname }}',
        reportFields: ['userFullname'],
        filterable: true,
        sortable: 'userFullname',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.OWNER',
        field: 'reportsOwner',
        fieldName: 'owner',
        fieldFormat: '{{ owner }}',
        reportFields: ['owner'],
        filterable: true,
        sortable: 'owner',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'deposits.OWNER_DESK',
        field: 'ownerDesk',
        fieldName: 'ownerDesk',
        fieldFormat: '{{ ownerDesk }}',
        reportFields: ['ownerDesk'],
        filterable: true,
        sortable: 'ownerDesk',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.ACCOUNT_NAME',
        field: 'accountName',
        fieldName: 'accountName',
        fieldFormat: '{{ accountName }}',
        reportFields: ['accountName'],
        filterable: false,
        sortable: 'accountName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.FTD_DATE',
        field: 'ftdDate',
        fieldName: 'ftdDate',
        fieldFormat: '{{ ftdDate }}',
        reportFields: ['ftdDate'],
        filterable: true,
        sortable: 'ftdDate',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'portfolio.table.TRADING_ACCOUNT_ID',
        field: 'tradingAccountId',
        fieldName: 'tradingAccountId',
        fieldFormat: '{{ tradingAccountId }}',
        reportFields: ['tradingAccountId'],
        filterable: false,
        sortable: 'tradingAccountId',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.TRANSACTION_EXTERNAL_ID',
        field: 'transactionExternalId',
        fieldName: 'transactionExternalId',
        fieldFormat: '{{ transactionExternalId }}',
        reportFields: ['transactionExternalId'],
        filterable: false,
        sortable: 'transactionExternalId',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.TRANSACTION_TYPE',
        field: 'transactionType',
        fieldName: 'transactionType',
        fieldFormat: '{{ transactionType }}',
        reportFields: ['transactionType'],
        filterable: false,
        sortable: 'transactionType',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'CLEARING_COMPANY',
        field: 'reportsClearingCompany',
        fieldName: 'clearingCompany',
        fieldFormat: '{{ clearingCompany }}',
        reportFields: ['clearingCompany'],
        filterable: true,
        sortable: 'clearingCompany',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.CHALLENGE_NAME',
        field: 'challengeName',
        fieldName: 'challengeName',
        fieldFormat: '{{ challengeName }}',
        reportFields: ['challengeName'],
        filterable: false,
        sortable: 'challengeName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.OFFER_NAME',
        field: 'offerName',
        fieldName: 'offerName',
        fieldFormat: '{{ offerName }}',
        reportFields: ['offerName'],
        filterable: false,
        sortable: 'offerName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'auditLogs.MARKETING_INFO',
        field: 'marketingInfo',
        fieldName: 'marketingInfo',
        fieldFormat: '{{ marketingInfo }}',
        reportFields: ['marketingInfo'],
        filterable: false,
        sortable: 'marketingInfo',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.CAMPAIGN',
        field: 'reportsCampaign',
        fieldName: 'campaign',
        fieldFormat: '{{ campaign }}',
        reportFields: ['campaign'],
        filterable: true,
        sortable: 'campaign',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.DESK',
        field: 'reportsDesk',
        fieldName: 'reportsDesk',
        fieldFormat: '{{ desk }}',
        reportFields: ['desk'],
        filterable: true,
        sortable: 'desk',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reporrts.titles.PROCESSING_EMPLOYEE',
        field: 'reportsByEmployee',
        fieldName: 'employee',
        fieldFormat: '{{ employee }}',
        reportFields: ['employee'],
        filterable: true,
        sortable: 'employee',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'BRAND',
        field: 'reportsBrand',
        fieldName: 'reportsBrand',
        fieldFormat: '{{ brand }}',
        reportFields: ['brand'],
        filterable: true,
        sortable: 'brand',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'COUNTRY',
        field: 'reportsCountry',
        fieldName: 'country',
        fieldFormat: '{{ country }}',
        reportFields: ['country'],
        filterable: true,
        sortable: 'country',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.VERIFIED_STATUS',
        field: 'reportsCustomerComplianceName',
        fieldName: 'customerComplianceName',
        fieldFormat: '{{ customerComplianceName }}',
        reportFields: ['customerComplianceName'],
        filterable: true,
        sortable: 'customerComplianceName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.NET_DEPOSITS',
        field: 'netDeposit',
        fieldName: 'netDeposit',
        fieldFormat: '{{ netDeposit | number: 2 }}',
        reportFields: ['netDeposit'],
        filterable: true,
        sortable: 'netDeposit',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.REAL',
        field: 'reportsReal',
        fieldName: 'real',
        fieldFormat: '{{ real }}',
        reportFields: ['real'],
        filterable: true,
        sortable: 'real',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.STATUS',
        field: 'transactionStatusCode',
        fieldName: 'status',
        fieldFormat: '{{ status }}',
        reportFields: ['status'],
        filterable: true,
        sortable: 'status',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.TOTAL_DEPOSIT_COUNT',
        field: 'totalDepositsCnt',
        fieldName: 'totalDepositsCnt',
        fieldFormat: '{{ totalDepositsCnt }}',
        reportFields: ['totalDepositsCnt'],
        filterable: true,
        sortable: 'totalDepositsCnt',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.ACCOUNT_CASH_PNL',
        field: 'accountCashPnl',
        fieldName: 'accountCashPnl',
        fieldFormat: '{{ accountCashPnl | number : 2 }}',
        reportFields: ['accountCashPnl'],
        filterable: true,
        sortable: 'accountCashPnl',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.ACCOUNT_PAYABLE_BALANCE',
        field: 'accountWithdrawableBalance',
        fieldName: 'accountWithdrawableBalance',
        fieldFormat: '{{ accountWithdrawableBalance  | number : 2 }}',
        reportFields: ['accountWithdrawableBalance'],
        filterable: true,
        sortable: 'accountWithdrawableBalance',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'contactsImportTable.fields.CREATED_AT',
        field: 'createdAt',
        fieldName: 'createdAt',
        fieldFormat: '{{ createdAt }}',
        reportFields: ['createdAt'],
        filterable: true,
        sortable: 'createdAt',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.CUSTOMER_CASH_PNL',
        field: 'customerCashPnl',
        fieldName: 'customerCashPnl',
        fieldFormat: '{{ customerCashPnl | number : 2 }}',
        reportFields: ['customerCashPnl'],
        filterable: true,
        sortable: 'customerCashPnl',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'contact.CUSTOMER_STATUS',
        field: 'customerStatusName',
        fieldName: 'customerStatusName',
        fieldFormat: '{{ customerStatusName }}',
        reportFields: ['customerStatusName'],
        filterable: true,
        sortable: 'customerStatusName',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.CUSTOMER_PAYABLE_BALANCE',
        field: 'customerWithdrawableBalance',
        fieldName: 'customerWithdrawableBalance',
        fieldFormat: '{{ customerWithdrawableBalance | number : 2 }}',
        reportFields: ['customerWithdrawableBalance'],
        filterable: true,
        sortable: 'customerWithdrawableBalance',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'portfolio.stats.TOTAL_EQUITY',
        field: 'equityTotal',
        fieldName: 'equityTotal',
        fieldFormat: '{{ equityTotal | number: 2 }}',
        reportFields: ['equityTotal'],
        filterable: true,
        sortable: 'equityTotal',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'common.EXTERNAL_ID',
        field: 'externalId',
        fieldName: 'externalId',
        fieldFormat: '{{ externalId }}',
        reportFields: ['externalId'],
        filterable: false,
        sortable: 'externalId',
        removable: true,
        show: false,
        type: 'common',
      },
    ],
  },
  assignUsersLimit: 10000,
  userEvents: {
    toggleItem: 'toggleItem',
    paging: 'paging',
  },
};
