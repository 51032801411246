import { Permissions } from '~/source/common/models/permission-structure';

export const customerReportsSettings = {
  table: {
    ngTable: {
      parameters: {
        count: 50,
        page: 1,
        sorting: {
          registrationDate: 'desc',
        },
      },
      settings: {
        counts: [],
      },
    },
    quickFilters_comment:
      'if quick filter is active by default. you must set its value in bgTable.parameters.filter above',
    quickFilters: {
      reportsRealContacts: {
        state: {
          isActive: true,
        },
      },
    },
    colsList: [
      {
        title: 'contact.dashboard.titles.REGISTRATION_DATE',
        field: 'registrationDate',
        fieldName: 'registrationDate',
        reportFields: ['registrationDate'],
        filterable: true,
        sortable: 'registrationDate',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'ID',
        field: 'customerId',
        fieldName: 'customerId',
        reportFields: ['customerId'],
        filterable: false,
        sortable: 'customerId',
        removable: true,
        show: true,
        type: 'common',
        className: 'prf-nowrap',
      },
      {
        title: 'NAME',
        field: 'customerName',
        fieldName: 'customerName',
        fieldFormat: '{{ customerName }}',
        reportFields: ['customerName'],
        filterable: false,
        sortable: 'customerName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'STATUS',
        field: 'status',
        fieldName: 'status',
        reportFields: ['status'],
        filterable: true,
        sortable: 'status',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.PHONE',
        field: 'phone',
        fieldName: 'phone',
        fieldFormat: '{{ phone }}',
        reportFields: ['phone'],
        filterable: false,
        sortable: 'phone',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.EMAIL',
        field: 'email',
        fieldName: 'email',
        reportFields: ['email'],
        filterable: false,
        sortable: 'email',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.COUNTRY',
        field: 'reportsCountry',
        fieldName: 'country',
        fieldFormat: '{{country}}',
        reportFields: ['country'],
        filterable: true,
        sortable: 'country',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.CITY',
        field: 'city',
        fieldName: 'city',
        fieldFormat: '{{city}}',
        reportFields: ['city'],
        filterable: false,
        sortable: 'city',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.CUSTOMER_COMPLIANCE_STATUS',
        field: 'reportsCustomerComplianceName',
        fieldName: 'customerComplianceName',
        fieldFormat: '{{ customerComplianceName }}',
        reportFields: ['customerComplianceName'],
        filterable: true,
        sortable: 'customerComplianceName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.BRAND',
        field: 'reportsBrand',
        fieldName: 'reportsBrand',
        fieldFormat: '{{brand}}',
        reportFields: ['brand'],
        filterable: true,
        sortable: 'brand',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.USER',
        field: 'reportsEmployee',
        fieldName: 'userFullname',
        fieldFormat: '{{userFullname}}',
        reportFields: ['userFullname'],
        filterable: true,
        sortable: 'userFullname',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.DESK',
        field: 'reportsDesk',
        fieldName: 'reportsDesk',
        reportFields: ['desk'],
        fieldFormat: '{{desk}}',
        filterable: true,
        sortable: 'desk',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.CAMPAIGN',
        field: 'reportsCampaign',
        fieldName: 'campaign',
        fieldFormat: '{{campaign}}',
        reportFields: ['campaign'],
        filterable: true,
        sortable: 'campaign',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.OFFER_NAME',
        field: 'productName',
        fieldName: 'productName',
        reportFields: ['productName'],
        filterable: false,
        sortable: 'productName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.MARKETING_INFO',
        field: 'marketingInfo',
        fieldName: 'marketingInfo',
        reportFields: ['marketingInfo'],
        filterable: false,
        sortable: 'marketingInfo',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.BALANCE_TOTAL',
        field: 'balanceTotal',
        fieldName: 'balanceTotal',
        reportFields: ['balanceTotal'],
        fieldFormat: '{{balanceTotal | number : 2 }}',
        filterable: true,
        sortable: 'balanceTotal',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.TRADING_PNL',
        field: 'pnlTotal',
        fieldName: 'pnlTotal',
        reportFields: ['pnlTotal'],
        fieldFormat: '{{pnlTotal | number : 2 }}',
        filterable: true,
        sortable: 'pnlTotal',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.REAL_PNL',
        field: 'cashPnl',
        fieldName: 'cashPnl',
        reportFields: ['cashPnl'],
        fieldFormat: '{{cashPnl | number : 2 }}',
        filterable: true,
        sortable: true,
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.PURCHASE_TOTAL',
        field: 'depositTotalProp',
        fieldName: 'depositTotalProp',
        reportFields: ['depositTotal'],
        fieldFormat: '{{depositTotal | number : 2 }}',
        filterable: true,
        sortable: 'depositTotalProp',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.TOTAL_PURCHASE_COUNT',
        field: 'totalDepositsCntProp',
        fieldName: 'totalPurchasesCntProp',
        reportFields: ['totalDepositsCnt'],
        fieldFormat: '{{totalDepositsCntProp}}',
        filterable: true,
        sortable: 'totalDepositsCntProp',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.PAYOUT_TOTAL',
        field: 'withdrawalTotalProp',
        fieldName: 'withdrawalTotalProp',
        reportFields: ['withdrawalTotal'],
        fieldFormat: '{{withdrawalTotalProp | number : 2 }}',
        filterable: true,
        sortable: 'withdrawalTotalProp',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.NET_PURCHASES',
        field: 'netDepositProp',
        fieldName: 'netDepositProp',
        reportFields: ['netDeposit'],
        fieldFormat: '{{netDepositProp | number : 2 }}',
        filterable: true,
        sortable: 'netDepositProp',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.LAST_WITHDRAWAL_DATE_PROP',
        field: 'lastWithdrawalDate',
        fieldName: 'lastWithdrawalDate',
        reportFields: ['lastWithdrawalDate'],
        filterable: true,
        sortable: 'lastWithdrawalDate',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.PAYABLE_BALANCE',
        field: 'withdrawableBalance',
        fieldName: 'withdrawableBalance',
        reportFields: ['withdrawableBalance'],
        fieldFormat: '{{withdrawableBalance | number : 2 }}',
        filterable: true,
        sortable: 'withdrawableBalance',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.FIRST_PURCHASE_AMOUNT',
        field: 'firstDepositAmountProp',
        fieldName: 'firstDepositAmountProp',
        reportFields: ['firstDepositAmount'],
        fieldFormat: '{{ firstDepositAmountProp | number : 2 }}',
        filterable: true,
        sortable: 'firstDepositAmountProp',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.FIRST_PURCHASE_AMOUNT_BASIC',
        field: 'firstDepositAmountBasicProp',
        fieldName: 'firstDepositAmountBasicProp',
        reportFields: ['firstDepositAmountBasic'],
        fieldFormat:
          '{{ firstDepositAmountBasicProp | baseCurrencyFormat : 2 : true }}',
        filterable: true,
        sortable: 'firstDepositAmountBasicProp',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.LAST_PURCHASE_DATE',
        field: 'lastDepositDateProp',
        fieldName: 'lastDepositDateProp',
        reportFields: ['lastDepositDate'],
        filterable: true,
        sortable: 'lastDepositDateProp',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.LAST_LOGIN_DATE',
        field: 'lastLoginDate',
        fieldName: 'lastLoginDate',
        reportFields: ['lastLoginDate'],
        filterable: true,
        sortable: 'lastLoginDate',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.LAST_CALL_DATE',
        field: 'lastCallDate',
        fieldName: 'lastCallDate',
        reportFields: ['lastCallDate'],
        filterable: true,
        sortable: 'lastCallDate',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.DESK_ASSIGNMENT_TIME',
        field: 'deskAssignmentTime',
        fieldName: 'deskAssignmentTime',
        reportFields: ['deskAssignmentTime'],
        fieldFormat: '{{deskAssignmentTime}}',
        filterable: true,
        sortable: 'deskAssignmentTime',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.PREV_DESK',
        field: 'prevDesk',
        fieldName: 'prevDesk',
        reportFields: ['prevDesk'],
        fieldFormat: '{{prevDesk}}',
        filterable: true,
        sortable: 'prevDesk',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.PREV_DESK_ASSIGNMENT_TIME',
        field: 'prevDeskAssignmentTime',
        fieldName: 'prevDeskAssignmentTime',
        reportFields: ['prevDeskAssignmentTime'],
        fieldFormat: '{{prevDeskAssignmentTime}}',
        filterable: true,
        sortable: 'prevDeskAssignmentTime',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.FIRST_CALL_DATE',
        field: 'firstCallDate',
        fieldName: 'firstCallDate',
        reportFields: ['firstCallDate'],
        filterable: true,
        sortable: 'firstCallDate',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.FIRST_CALL_USER',
        field: 'firstCallUser',
        fieldName: 'firstCallUser',
        reportFields: ['firstCallUser'],
        filterable: true,
        sortable: 'firstCallUser',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.FIRST_COMMENT_DATE',
        field: 'firstCommentDate',
        fieldName: 'firstCommentDate',
        reportFields: ['firstCommentDate'],
        filterable: true,
        sortable: 'firstCommentDate',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.FIRST_COMMUNICATION_USER',
        field: 'firstCommunicationUser',
        fieldName: 'firstCommunicationUser',
        reportFields: ['firstCommunicationUser'],
        filterable: true,
        sortable: 'firstCommunicationUser',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.CALL_COUNT_BEFORE_FTP',
        field: 'callCountBeforeFtdProp',
        fieldName: 'callCountBeforeFtdProp',
        reportFields: ['callCountBeforeFtd'],
        filterable: true,
        sortable: 'callCountBeforeFtdProp',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.CALLS_COUNT',
        field: 'callsCount',
        fieldName: 'callsCount',
        reportFields: ['callsCount'],
        filterable: true,
        sortable: 'callsCount',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.UNIQUE_CALLERS_COUNT',
        field: 'callersCount',
        fieldName: 'callersCount',
        reportFields: ['callersCount'],
        filterable: true,
        sortable: 'callersCount',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.REGISTRATION_DATE_COUNT',
        field: 'registrationDateCount',
        fieldName: 'registrationDateCount',
        reportFields: ['registrationDateCount'],
        fieldFormat: '{{registrationDateCount }}',
        filterable: true,
        sortable: 'registrationDateCount',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.FTD_DAYS_COUNT',
        field: 'ftdDaysCount',
        fieldName: 'ftdDaysCount',
        reportFields: ['ftdDaysCount'],
        fieldFormat: '{{ftdDaysCount}}',
        filterable: true,
        sortable: 'ftdDaysCount',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.BONUS_TOTAL',
        field: 'bonusTotal',
        fieldName: 'bonusTotal',
        reportFields: ['bonusTotal'],
        fieldFormat: '{{bonusTotal | number : 2 }}',
        filterable: true,
        sortable: 'bonusTotal',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.TRADES_COUNT',
        field: 'tradeCount',
        fieldName: 'tradeCount',
        reportFields: ['tradeCount'],
        filterable: true,
        sortable: 'tradeCount',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'filters.REAL',
        field: 'reportsRealContacts',
        fieldFormat: '{{ real }}',
        fieldName: 'real',
        reportFields: ['real'],
        filterable: false,
        sortable: 'real',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.PHONE2',
        field: 'phone2',
        fieldName: 'phone2',
        fieldFormat: '{{ phone2 }}',
        reportFields: ['phone2'],
        filterable: false,
        sortable: 'phone2',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'contact.GENDER',
        field: 'gender',
        fieldName: 'gender',
        reportFields: ['gender'],
        filterable: false,
        sortable: 'gender',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'contact.BIRTHDAY',
        field: 'birthday',
        fieldFormat: '{{ birthday }}',
        fieldName: 'birthday',
        reportFields: ['birthday'],
        filterable: true,
        sortable: 'birthday',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.FTD_ID',
        field: 'ftdId',
        fieldName: 'ftdId',
        reportFields: ['ftdId'],
        fieldFormat: '{{ftdId > 0 ? ftdId : "" }}',
        filterable: false,
        sortable: 'ftdId',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.OPEN_POSITIONS',
        field: 'openTradeCount',
        fieldName: 'openTradeCount',
        reportFields: ['openTradeCount'],
        fieldFormat: '{{openTradeCount}}',
        filterable: true,
        sortable: true,
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.LAST_POSITION_DATE',
        field: 'lastPositionDate',
        fieldName: 'lastTradeDate',
        reportFields: ['lastTradeDate'],
        filterable: true,
        sortable: 'lastTradeDate',
        removable: true,
        show: false,
        type: 'common',
      },
    ],
  },
  assignUsersLimit: 10000,
  userEvents: {
    toggleItem: 'toggleItem',
    paging: 'paging',
  },
};
