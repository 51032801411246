import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './reports-platform-type-selector.component.html';
import { PlatformTypesService } from '~/source/common/services/platforms-types';
import { PlatformTypeCode } from '@proftit/crm.api.models.enums';
import {
  PlatformType,
  PlatformTypeOption,
} from '@proftit/crm.api.models.entities';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { Entity } from '@proftit/crm.api.models.general';

const styles = require('./contacts-platform-type-selector.component.scss');

const NOT_ALLOWED_PLATFORMS = [PlatformTypeCode.Binary];
export const SELECTED_REPORTS_PLATFORM_TYPE_CODE_KEY =
  'prf.contacts.dashboard.reportsPlatformTypeSelector.lastSelectedTypeCode';
const defaultTypeCode = PlatformTypeCode.Forex;

export class ReportsPlatformTypeSelectorController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  typesList$ = new rx.Subject<PlatformTypeOption[] | null>();
  typesList: PlatformTypeOption[] = [];
  model: PlatformType;
  selectedType = new rx.Subject<PlatformTypeOption>();

  onSelect: () => void;
  /* @ngInject */
  constructor(readonly platformTypesService: PlatformTypesService) {
    useStreams(
      [this.streamTypesList(), this.streamSaveSelectedTypeCodeToLocalStorage()],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamTypesList() {
    return rx.pipe(
      () => this.lifecycles.onInitShared$.pipe(rx.filter((x) => x)),
      rx.switchMap(() => this.getAllowedPlatformType()),
      rx.map((types) => {
        return types.map((type) => ({
          ...type,
          selected: false,
          displayName: type.code === PlatformTypeCode.Forex ? 'CFD' : type.name,
        })) as PlatformTypeOption[];
      }),
      rx.tap((types) => {
        const lastSelectedTypeCode = this.getLastSelectedTypeCode();
        const lastSelectedType = types.find(
          (t) => t.code === lastSelectedTypeCode,
        );
        types = types.map((type) => {
          type.selected = type.code === lastSelectedTypeCode;
          return type;
        });
        this.typesList$.next(types);
        this.typesList = types;
        this.selectedType.next(lastSelectedType || types[0]);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamSaveSelectedTypeCodeToLocalStorage() {
    return rx.pipe(
      () => this.selectedType,
      rx.tap((type) => {
        const { code } = type;
        localStorage.setItem(SELECTED_REPORTS_PLATFORM_TYPE_CODE_KEY, code);
        this.typesList = this.typesList.map((t) => ({
          ...t,
          selected: t.code === code,
        }));
        this.model = type;
      }),
      shareReplayRefOne(),
    )(null);
  }

  getLastSelectedTypeCode() {
    return (
      localStorage.getItem(SELECTED_REPORTS_PLATFORM_TYPE_CODE_KEY) ||
      defaultTypeCode
    );
  }

  selectType(type: PlatformTypeOption) {
    this.selectedType.next(type);
  }

  async getAllowedPlatformType(): Promise<PlatformType[]> {
    const data = await this.platformTypesService.getListWithQuery();

    const types: PlatformType[] = data
      .plain()
      .filter((t) => !NOT_ALLOWED_PLATFORMS.includes(t.code));
    return types as PlatformType[];
  }

  idSelectionCompare = (a: Entity, b: Entity) => {
    return _.get(['id'], a) === _.get(['id'], b);
  };
}

export const ReportsPlatformTypeSelectorComponent = {
  template,
  controller: ReportsPlatformTypeSelectorController,
  bindings: {
    onSelect: '&',
    model: '=',
  },
};
