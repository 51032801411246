let CALCULATION_BASE;

(function (CALCULATION_BASE) {
  CALCULATION_BASE["BALANCE"] = "balance";
  CALCULATION_BASE["EQUITY"] = "equity";
})(CALCULATION_BASE || (CALCULATION_BASE = {}));
//depends on service response it can be currencyId or currency as expanded entity

let CHALLENGE_TYPES;

(function (CHALLENGE_TYPES) {
  CHALLENGE_TYPES["PROP"] = "PROP";
  CHALLENGE_TYPES["TRIAL"] = "TRIAL";
})(CHALLENGE_TYPES || (CHALLENGE_TYPES = {}));

const ALLOWED_PLATFORMS_TYPE_CODES = ['forex', 'prop'];

export { ALLOWED_PLATFORMS_TYPE_CODES, CALCULATION_BASE, CHALLENGE_TYPES };
