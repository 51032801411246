export const creditsReportsSettings = {
  table: {
    ngTable: {
      parameters: {
        count: 50,
        page: 1,
        sorting: {
          createdAt: 'desc',
        },
      },
      settings: {
        counts: [],
      },
    },
    quickFilters_comment:
      'if quick filter is active by default. you must set its value in bgTable.parameters.filter above',
    quickFilters: {
      reportsRealContacts: {
        state: {
          isActive: true,
        },
      },
    },
    colsList: [
      {
        title: 'contactsImportTable.fields.CREATED_AT',
        field: 'createdAt',
        fieldName: 'createdAt',
        fieldFormat: '{{ createdAt }}',
        reportFields: ['createdAt'],
        filterable: true,
        sortable: 'createdAt',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'CUSTOMER_ID',
        field: 'customerId',
        fieldName: 'customerId',
        fieldFormat: '{{ customerId }}',
        reportFields: ['customerId'],
        filterable: false,
        sortable: 'customerId',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.CUSTOMER_NAME',
        field: 'customerName',
        fieldName: 'customerName',
        fieldFormat: '{{ customerName }}',
        reportFields: ['customerName'],
        filterable: false,
        sortable: 'customerName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.EMAIL',
        field: 'email',
        fieldName: 'email',
        fieldFormat: '{{ email }}',
        reportFields: ['email'],
        filterable: false,
        sortable: 'email',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'PHONE',
        field: 'phone',
        fieldName: 'phone',
        fieldFormat: '{{ phone }}',
        reportFields: ['phone'],
        filterable: false,
        sortable: 'phone',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.AMOUNT',
        field: 'amount',
        fieldName: 'amount',
        fieldFormat: '{{ amount | number : 2 }}',
        reportFields: ['amount'],
        filterable: true,
        sortable: 'amount',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.AMOUNT_BASIC',
        field: 'amountBasic',
        fieldName: 'amountBasic',
        fieldFormat: '{{ amountBasic | baseCurrencyFormat : 2 : true }}',
        reportFields: ['amountBasic'],
        filterable: true,
        sortable: 'amountBasic',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.CURRENCY',
        field: 'currency',
        fieldName: 'currency',
        fieldFormat: '{{ currency }}',
        reportFields: ['currency'],
        filterable: false,
        sortable: 'currency',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.NOTE',
        field: 'note',
        fieldName: 'note',
        fieldFormat: '{{ note }}',
        reportFields: ['note'],
        filterable: false,
        sortable: 'note',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.EMPLOYEE',
        field: 'reportsByEmployee',
        fieldName: 'employee',
        fieldFormat: '{{ employee }}',
        reportFields: ['employee'],
        filterable: true,
        sortable: 'employee',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.DESK',
        field: 'reportsDesk',
        fieldName: 'reportsDesk',
        fieldFormat: '{{ desk }}',
        reportFields: ['desk'],
        filterable: true,
        sortable: 'desk',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.OFFER_NAME',
        field: 'offerName',
        fieldName: 'offerName',
        fieldFormat: '{{ offerName }}',
        reportFields: ['offerName'],
        filterable: false,
        sortable: 'offerName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'auditLogs.MARKETING_INFO',
        field: 'marketingInfo',
        fieldName: 'marketingInfo',
        fieldFormat: '{{ marketingInfo }}',
        reportFields: ['marketingInfo'],
        filterable: false,
        sortable: 'marketingInfo',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.CAMPAIGN',
        field: 'reportsCampaign',
        fieldName: 'campaign',
        fieldFormat: '{{ campaign }}',
        reportFields: ['campaign'],
        filterable: true,
        sortable: 'campaign',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'BRAND',
        field: 'reportsBrand',
        fieldName: 'reportsBrand',
        fieldFormat: '{{ brand }}',
        reportFields: ['brand'],
        filterable: true,
        sortable: 'brand',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'COUNTRY',
        field: 'reportsCountry',
        fieldName: 'country',
        fieldFormat: '{{ country }}',
        reportFields: ['country'],
        filterable: true,
        sortable: 'country',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'portfolio.stats.TOTAL_BALANCE',
        field: 'balanceLTotal',
        fieldName: 'balanceLTotal',
        fieldFormat: '{{ balanceLTotal | number : 2 }}',
        reportFields: ['balanceLTotal'],
        filterable: true,
        sortable: 'balanceLTotal',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.TOTAL_PURCHASES',
        field: 'depositTotal',
        fieldName: 'depositTotal',
        fieldFormat: '{{ depositTotal | number : 2 }}',
        reportFields: ['depositTotal'],
        filterable: true,
        sortable: 'depositTotal',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.TOTAL_PURCHASE_COUNT',
        field: 'totalDepositsCnt',
        fieldName: 'totalDepositsCnt',
        fieldFormat: '{{ totalDepositsCnt }}',
        reportFields: ['totalDepositsCnt'],
        filterable: true,
        sortable: 'totalDepositsCnt',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.TOTAL_PAYOUTS',
        field: 'withdrawalTotal',
        fieldName: 'withdrawalTotal',
        fieldFormat: '{{ withdrawalTotal | number : 2 }}',
        reportFields: ['withdrawalTotal'],
        filterable: true,
        sortable: 'withdrawalTotal',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.CHALLENGE_NAME',
        field: 'challengeName',
        fieldName: 'challengeName',
        fieldFormat: '{{ challengeName }}',
        reportFields: ['challengeName'],
        filterable: false,
        sortable: 'challengeName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.ACCOUNT_NAME',
        field: 'tradingAccount',
        fieldName: 'tradingAccount',
        fieldFormat: '{{ tradingAccount }}',
        reportFields: ['tradingAccount'],
        filterable: false,
        sortable: 'tradingAccount',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'portfolio.table.PLATFORM_ID',
        field: 'externalAccountId',
        fieldName: 'externalAccountId',
        fieldFormat: '{{ externalAccountId }}',
        reportFields: ['externalAccountId'],
        filterable: false,
        sortable: 'externalAccountId',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.REAL',
        field: 'reportsReal',
        fieldName: 'real',
        fieldFormat: '{{ real }}',
        reportFields: ['real'],
        filterable: true,
        sortable: 'real',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.VERIFIED_STATUS',
        field: 'reportsByKyc',
        fieldName: 'kyc',
        fieldFormat: '{{ kyc }}',
        reportFields: ['kyc'],
        filterable: true,
        sortable: 'kyc',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.LAST_COMMUNICATION_DATE',
        field: 'lastCommunicationDate',
        fieldName: 'lastCommunicationDate',
        fieldFormat: '{{ lastCommunicationDate }}',
        reportFields: ['lastCommunicationDate'],
        filterable: true,
        sortable: 'lastCommunicationDate',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.LAST_LOGIN_DATE',
        field: 'lastLoginDate',
        fieldName: 'lastLoginDate',
        fieldFormat: '{{ lastLoginDate }}',
        reportFields: ['lastLoginDate'],
        filterable: true,
        sortable: 'lastLoginDate',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.ACCOUNT_CASH_PNL',
        field: 'accountCashPnl',
        fieldName: 'accountCashPnl',
        fieldFormat: '{{ accountCashPnl | number : 2 }}',
        reportFields: ['accountCashPnl'],
        filterable: true,
        sortable: 'accountCashPnl',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.ACCOUNT_PAYABLE_BALANCE',
        field: 'accountWithdrawableBalance',
        fieldName: 'accountWithdrawableBalance',
        fieldFormat: '{{ accountWithdrawableBalance | number : 2 }}',
        reportFields: ['accountWithdrawableBalance'],
        filterable: true,
        sortable: 'accountWithdrawableBalance',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.CUSTOMER_CASH_PNL',
        field: 'customerCashPnl',
        fieldName: 'customerCashPnl',
        fieldFormat: '{{ customerCashPnl | number : 2 }}',
        reportFields: ['customerCashPnl'],
        filterable: true,
        sortable: 'customerCashPnl',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.CUSTOMER_PAYABLE_BALANCE',
        field: 'customerWithdrawableBalance',
        fieldName: 'customerWithdrawableBalance',
        fieldFormat: '{{ customerWithdrawableBalance | number : 2 }}',
        reportFields: ['customerWithdrawableBalance'],
        filterable: true,
        sortable: 'customerWithdrawableBalance',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'DEPARTMENT',
        field: 'reportsDepartment',
        fieldName: 'department',
        fieldFormat: '{{ department }}',
        reportFields: ['department'],
        filterable: true,
        sortable: 'department',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'portfolio.stats.TOTAL_EQUITY',
        field: 'equityTotal',
        fieldName: 'equityTotal',
        fieldFormat: '{{ equityTotal | number : 2 }}',
        reportFields: ['equityTotal'],
        filterable: true,
        sortable: 'equityTotal',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.FIRST_PURCHASE_AMOUNT',
        field: 'firstDepositAmount',
        fieldName: 'firstDepositAmount',
        fieldFormat: '{{ firstDepositAmount | number : 2 }}',
        reportFields: ['firstDepositAmount'],
        filterable: true,
        sortable: 'firstDepositAmount',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.FIRST_PURCHASE_AMOUNT_BASIC',
        field: 'firstDepositAmountBasic',
        fieldName: 'firstDepositAmountBasic',
        fieldFormat: '{{ firstDepositAmountBasic | number : 2 }}',
        reportFields: ['firstDepositAmountBasic'],
        filterable: true,
        sortable: 'firstDepositAmountBasic',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'common.GROUP',
        field: 'groupName',
        fieldName: 'groupName',
        fieldFormat: '{{ groupName }}',
        reportFields: ['groupName'],
        filterable: false,
        sortable: 'groupName',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'common.LANGUAGE',
        field: 'reportsLanguage',
        fieldName: 'language',
        fieldFormat: '{{ language }}',
        reportFields: ['language'],
        filterable: true,
        sortable: 'language',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.LAST_TRADE_DATE',
        field: 'lastTradedAte',
        fieldName: 'lastTradedAte',
        fieldFormat: '{{ lastTradedAte }}',
        reportFields: ['lastTradedAte'],
        filterable: true,
        sortable: 'lastTradedAte',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.OPEN_TRADE_COUNT',
        field: 'openTradeCount',
        fieldName: 'openTradeCount',
        fieldFormat: '{{ openTradeCount }}',
        reportFields: ['openTradeCount'],
        filterable: true,
        sortable: 'openTradeCount',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'PHONE2',
        field: 'phone2',
        fieldName: 'phone2',
        fieldFormat: '{{ phone2 }}',
        reportFields: ['phone2'],
        filterable: false,
        sortable: 'phone2',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.PROMO_CODE_ID',
        field: 'promoCodeId',
        fieldName: 'promoCodeId',
        fieldFormat: '{{ promoCodeId > 0 ? promoCodeId : "" }}',
        reportFields: ['promoCodeId'],
        filterable: false,
        sortable: 'promoCodeId',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.TRADES_COUNT',
        field: 'tradeCount',
        fieldName: 'tradeCount',
        fieldFormat: '{{ tradeCount }}',
        reportFields: ['tradeCount'],
        filterable: true,
        sortable: 'tradeCount',
        removable: true,
        show: false,
        type: 'common',
      },
    ],
  },
  assignUsersLimit: 10000,
  userEvents: {
    toggleItem: 'toggleItem',
    paging: 'paging',
  },
};
