import { Currency } from '@proftit/crm.api.models.entities';
import { TradingAccount } from './trading-account';

export type PeriodsUnits = 'days' | 'weeks' | 'months' | 'years';
export type CalculationMethod = 'fixed' | 'percentage';
export type CalculationBase = 'balance' | 'equity';
export type ChallengePhaseType = 'funded' | 'regular';

export enum CALCULATION_BASE {
  BALANCE = 'balance',
  EQUITY = 'equity',
}

export type ChallengeAccountBalanceConfig = {
  id?: number;
  challengeId?: number;
  challengeGroupId?: number;
  accountBalance: number | null;
  entryFee: number | null;
  currency: Currency;
};

export type ChallengeAsset = {
  value: string;
  name: string;
  label: string;
};

export type ChallengeEarningBase = {
  value: number | null;
};

export type ChallengeEarning = ChallengeEarningBase & {
  calculationType: CalculationMethod;
};

export type PrizeAmount = ChallengeEarningBase & {
  calculationType: 'percentage' | 'fixed';
};

export type ProfitSplit = ChallengeEarningBase & {
  calculationType: 'percentage' | 'fixed';
};

export type ChallengePhasePeriodField = {
  isActive: boolean;
  value: number | null;
  periodsUnits: PeriodsUnits;
};

export type ChallengePhaseNumberField = {
  isActive: boolean;
  value: number | null;
};

export type ChallengePhaseCalculationField = ChallengePhaseNumberField & {
  calculationType: CalculationMethod;
  calculationBasis: CalculationBase;
};

export type ChallengePhaseFields =
  | ChallengePhaseCalculationField
  | ChallengePhasePeriodField
  | ChallengePhaseNumberField
  | ChallengeEarning
  | ChallengeAsset[];

export type ChallengePhase = {
  id?: number;
  challengeId?: number;
  name: string;
  description: string;
  isFunded: boolean;
  isActive: boolean;
  order: number;
  fields: {
    tradingPeriod: ChallengePhasePeriodField;
    minTradingPeriod: ChallengePhasePeriodField;
    maxLoss: ChallengePhaseCalculationField;
    maxDailyLoss: ChallengePhaseCalculationField;
    profitTarget: ChallengePhaseCalculationField;
    earning?: ChallengeEarning;
    assets?: ChallengeAsset[];
  };
  createdAt?: string;
  isValid?: boolean;
  tradingAccountId?: number;
  startedAt?: string;
  status?: string;
  tradingAccount?: TradingAccount;
};

//diff in payload and entity
//tradingPeriod: ChallengePhasePeriodField; => tradingPeriod: number;
//minTradingPeriod: ChallengePhasePeriodField; => minTradingPeriod: number;
//assets?: ChallengeAsset[];assets?: number[];

export type ChallengePhasePayload = Omit<ChallengePhase, 'fields'> & {
  fields: {
    tradingPeriod: number;
    minTradingPeriod: number;
    maxLoss: ChallengePhaseCalculationField;
    maxDailyLoss: ChallengePhaseCalculationField;
    profitTarget: ChallengePhaseCalculationField;
    profitSplit?: ProfitSplit;
    prizeAmount?: PrizeAmount;
    assets?: string[];
  };
};

const challenge = {
  id: 1,
  challengeGroupId: 1,
  challengeBalanceGroupId: 1,
  currencyId: 1,
  name: 'Challenge 1',
  balanceAmount: 10000,
  entryFee: 300,
  phases: [],
};

//diff in response and entity
//depends on service response it can be currencyId or currency as expanded entity
export type Challenge = {
  id?: number;
  isActive: boolean;
  challengeGroupId: number;
  challengeBalanceGroupId: number;
  currencyId?: number;
  currency?: Currency;
  name: string;
  balanceAmount: number;
  entryFee: number;
  phases: ChallengePhase[] | ChallengePhasePayload[];
  type?: ChallengeTypes;
  status?: string;
};

export type ChallengeGroup = {
  id?: number;
  brandId: number;
  name: string;
  description: string;
  isActive: boolean;
  challenges: Challenge[];
  type: ChallengeTypes;
};

export type ChallengeGroupCreate = {
  name: string;
  description: string;
  isActive: boolean;
  type: ChallengeTypes;
};

export type ChallengeBalanceGroup = {
  id: number;
  name: string;
  challengeGroupId: number;
};

export type ChallengeBalanceGroupsCreate = {
  name: string;
};

export type ChallengeTypes = 'PROP' | 'TRIAL';

export enum CHALLENGE_TYPES {
  PROP = 'PROP',
  TRIAL = 'TRIAL',
}
