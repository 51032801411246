import PositionsTableBasePopupController from '../../common/positions/positions-table-popup-base-controller';
import positionsSettings from './positions-forex-settings.json';
import { calculateForexPositionTableCols } from './calculate-forex-position-table-cols';
import * as _ from '@proftit/lodash';
import { DisplayContext } from './display-contenxt';
import { AccountType } from '@proftit/crm.api.models.entities';

class PositionTableForexPopupController extends PositionsTableBasePopupController {
  calculateCols = _.memoize(calculateForexPositionTableCols);
  isPortfolioPage: boolean;

  get cols() {
    if (this.isPortfolioPage) {
      return this.calculateCols(
        positionsSettings.tableColumns,
        this.account.platform,
        DisplayContext.PortfolioMain,
      );
    }
    return this.calculateCols(
      positionsSettings.tableColumns,
      this.account.platform,
      DisplayContext.ContactExpand,
    );
  }

  get ngTableSettings() {
    return { ...positionsSettings.tablePopup.ngTable };
  }

  get tableKey() {
    return 'positionsForex';
  }

  /**
   * this function is called by parent
   * @returns {*}
   */
  query() {
    const accountType =
      this.account.type === 'prop'
        ? ('forex' as AccountType)
        : this.account.type; //TODO: Temporary solution - final solution will be tackle on CRMPRFT-587
    return this.dataServiceInstance
      .getPositionsResource(this.customer.id, accountType, this.account.id)
      .expand([
        'currency',
        'positionStatus',
        'tradeAsset',
        'tradingAccount.currency',
        'tradingAccount.platform',
        'tradingAccount.customer',
        'tradingAccount.customer.brand',
      ]);
  }

  get requiredApiFilters() {
    return this.isPortfolioPage ? { positionStatusCode: 'forex_open' } : {};
  }
}

export default PositionTableForexPopupController;
