export const openWithdrawalsReportsSettings = {
  table: {
    ngTable: {
      parameters: {
        count: 50,
        page: 1,
        filter: {
          status: 'Requested',
        },
        sorting: {
          requestedAt: 'desc',
        },
      },
      settings: {
        counts: [],
      },
    },
    quickFilters_comment:
      'if quick filter is active by default. you must set its value in bgTable.parameters.filter above',
    quickFilters: {
      reportsRealContacts: {
        state: {
          isActive: true,
        },
      },
    },
    colsList: [
      {
        title: 'reports.titles.REQUESTED_DATE',
        field: 'requestedAt',
        fieldName: 'requestedAt',
        fieldFormat: '{{ requestedAt }}',
        reportFields: ['requestedAt'],
        filterable: true,
        sortable: 'requestedAt',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'CUSTOMER_ID',
        field: 'customerId',
        fieldName: 'customerId',
        fieldFormat: '{{ customerId }}',
        reportFields: ['customerId'],
        filterable: false,
        sortable: 'customerId',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.CUSTOMER_NAME',
        field: 'customerName',
        fieldName: 'customerName',
        fieldFormat: '{{ customerName }}',
        reportFields: ['customerName'],
        filterable: false,
        sortable: 'customerName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.AMOUNT_REQUESTED',
        field: 'amountRequested',
        fieldName: 'amountRequested',
        fieldFormat: '{{ amountRequested | number : 2 }}',
        reportFields: ['amountRequested'],
        filterable: true,
        sortable: 'amountRequested',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.AMOUNT_REQUESTED_BASIC',
        field: 'amountRequestedBasic',
        fieldName: 'amountRequestedBasic',
        fieldFormat:
          '{{ amountRequestedBasic | baseCurrencyFormat : 2 : true }}',
        reportFields: ['amountRequestedBasic'],
        filterable: true,
        sortable: 'amountRequestedBasic',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.CURRENCY',
        field: 'currency',
        fieldName: 'currency',
        fieldFormat: '{{ currency }}',
        reportFields: ['currency'],
        filterable: false,
        sortable: 'currency',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.REASON',
        field: 'reason',
        fieldName: 'reason',
        fieldFormat: '{{ reason }}',
        reportFields: ['reason'],
        filterable: false,
        sortable: 'reason',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.NOTE',
        field: 'note',
        fieldName: 'note',
        fieldFormat: '{{ note }}',
        reportFields: ['note'],
        filterable: false,
        sortable: 'note',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'credit.PAYOUT_TYPE',
        field: 'withdrawalType',
        fieldName: 'withdrawalType',
        fieldFormat: '{{ withdrawalType }}',
        reportFields: ['withdrawalType'],
        filterable: true,
        sortable: 'withdrawalType',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.OWNER',
        field: 'reportsOwner',
        fieldName: 'owner',
        fieldFormat: '{{ owner }}',
        reportFields: ['owner'],
        filterable: true,
        sortable: 'owner',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'deposits.OWNER_DESK',
        field: 'ownerDesk',
        fieldName: 'ownerDesk',
        fieldFormat: '{{ ownerDesk }}',
        reportFields: ['ownerDesk'],
        filterable: true,
        sortable: 'ownerDesk',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.ACCOUNT_NAME',
        field: 'accountName',
        fieldName: 'accountName',
        fieldFormat: '{{ accountName }}',
        reportFields: ['accountName'],
        filterable: false,
        sortable: 'accountName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'portfolio.table.TRADING_ACCOUNT_ID',
        field: 'tradingAccountId',
        fieldName: 'tradingAccountId',
        fieldFormat: '{{ tradingAccountId }}',
        reportFields: ['tradingAccountId'],
        filterable: false,
        sortable: 'tradingAccountId',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'portfolio.table.PLATFORM_ID',
        field: 'externalAccountId',
        fieldName: 'externalAccountId',
        fieldFormat: '{{ externalAccountId }}',
        reportFields: ['externalAccountId'],
        filterable: false,
        sortable: 'externalAccountId',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.PLATFORM',
        field: 'platform',
        fieldName: 'platform',
        fieldFormat: '{{ platform }}',
        reportFields: ['platform'],
        filterable: false,
        sortable: 'platform',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.OFFER_NAME',
        field: 'offerName',
        fieldName: 'offerName',
        fieldFormat: '{{ offerName }}',
        reportFields: ['offerName'],
        filterable: false,
        sortable: 'offerName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'auditLogs.MARKETING_INFO',
        field: 'marketingInfo',
        fieldName: 'marketingInfo',
        fieldFormat: '{{ marketingInfo }}',
        reportFields: ['marketingInfo'],
        filterable: false,
        sortable: 'marketingInfo',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.CAMPAIGN',
        field: 'reportsCampaign',
        fieldName: 'campaign',
        fieldFormat: '{{ campaign }}',
        reportFields: ['campaign'],
        filterable: true,
        sortable: 'campaign',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.DESK',
        field: 'reportsDesk',
        fieldName: 'reportsDesk',
        fieldFormat: '{{ desk }}',
        reportFields: ['desk'],
        filterable: true,
        sortable: 'desk',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.EMPLOYEE',
        field: 'reportsByEmployee',
        fieldName: 'employee',
        fieldFormat: '{{ employee }}',
        reportFields: ['employee'],
        filterable: true,
        sortable: 'employee',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'BRAND',
        field: 'reportsBrand',
        fieldName: 'reportsBrand',
        fieldFormat: '{{ brand }}',
        reportFields: ['brand'],
        filterable: true,
        sortable: 'brand',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'COUNTRY',
        field: 'reportsCountry',
        fieldName: 'reportsCountry',
        fieldFormat: '{{ country }}',
        reportFields: ['country'],
        filterable: true,
        sortable: 'country',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.VERIFIED_STATUS',
        field: 'reportsCustomerComplianceName',
        fieldName: 'customerComplianceName',
        fieldFormat: '{{ customerComplianceName }}',
        reportFields: ['customerComplianceName'],
        filterable: true,
        sortable: 'customerComplianceName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.PAYOUT_REQUEST_ID',
        field: 'id',
        fieldName: 'id',
        fieldFormat: '{{ id }}',
        reportFields: ['id'],
        filterable: false,
        sortable: 'id',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.CHALLENGE_NAME',
        field: 'challengeName',
        fieldName: 'challengeName',
        fieldFormat: '{{ challengeName }}',
        reportFields: ['challengeName'],
        filterable: false,
        sortable: 'challengeName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.REAL',
        field: 'reportsReal',
        fieldName: 'real',
        fieldFormat: '{{ real }}',
        reportFields: ['real'],
        filterable: true,
        sortable: 'real',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.STATUS',
        field: 'status',
        fieldName: 'status',
        fieldFormat: '{{ status }}',
        reportFields: ['status'],
        filterable: true,
        sortable: 'status',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.ACCOUNT_CASH_PNL',
        field: 'accountCashPnl',
        fieldName: 'accountCashPnl',
        fieldFormat: '{{ accountCashPnl | number : 2 }}',
        reportFields: ['accountCashPnl'],
        filterable: true,
        sortable: 'accountCashPnl',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.ACCOUNT_PAYABLE_BALANCE',
        field: 'accountWithdrawableBalance',
        fieldName: 'accountWithdrawableBalance',
        fieldFormat: '{{ accountWithdrawableBalance | number : 2 }}',
        reportFields: ['accountWithdrawableBalance'],
        filterable: true,
        sortable: 'accountWithdrawableBalance',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.CUSTOMER_CASH_PNL',
        field: 'customerCashPnl',
        fieldName: 'customerCashPnl',
        fieldFormat: '{{ customerCashPnl | number : 2 }}',
        reportFields: ['customerCashPnl'],
        filterable: true,
        sortable: 'customerCashPnl',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.CUSTOMER_PAYABLE_BALANCE',
        field: 'customerWithdrawableBalance',
        fieldName: 'customerWithdrawableBalance',
        fieldFormat: '{{ customerWithdrawableBalance | number : 2 }}',
        reportFields: ['customerWithdrawableBalance'],
        filterable: true,
        sortable: 'customerWithdrawableBalance',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'DEPARTMENT',
        field: 'reportsDepartment',
        fieldName: 'department',
        fieldFormat: '{{ department }}',
        reportFields: ['department'],
        filterable: true,
        sortable: 'department',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'portfolio.stats.TOTAL_EQUITY',
        field: 'equityTotal',
        fieldName: 'equityTotal',
        fieldFormat: '{{ equityTotal | number :  2 }}',
        reportFields: ['equityTotal'],
        filterable: true,
        sortable: 'equityTotal',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'common.LANGUAGE',
        field: 'reportsLanguage',
        fieldName: 'language',
        fieldFormat: '{{ language }}',
        reportFields: ['language'],
        filterable: true,
        sortable: 'language',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'LAST_UPDATED_DATE',
        field: 'updatedAt',
        fieldName: 'updatedAt',
        fieldFormat: '{{ updatedAt }}',
        reportFields: ['updatedAt'],
        filterable: true,
        sortable: 'updatedAt',
        removable: true,
        show: false,
        type: 'common',
      },
    ],
  },
  assignUsersLimit: 10000,
  userEvents: {
    toggleItem: 'toggleItem',
    paging: 'paging',
  },
};
