import angular from 'angular';
import { ChallengeAccountContainerComponent } from '~/source/contact/contact-page/prop-account/challenge-account-container/challenge-account-container.component';
import { ChallengeAccountInfoComponent } from '~/source/contact/contact-page/prop-account/challenge-account-info/challenge-account-info.component';
import ChallengePurchaseTableComponent from '~/source/contact/contact-page/prop-account/challenge-purchase-table/challenge-purchase-table.component';
import ConfirmChallengePurchasePopupController from '~/source/contact/contact-page/prop-account/confirm-challenge-purchase-popup/confirm-challenge-purchase-popup.controller';
import { ChallengePhaseContainerComponent } from '~/source/contact/contact-page/prop-account/challenge-phase-container/challenge-phase-container.component';
import TradingAccountPropComponent from '~/source/contact/contact-page/prop-account/trading-account-prop/trading-account-prop.component';
import { PropPhaseStatsTableComponent } from '~/source/contact/contact-page/prop-account/prop-phase-stats-table/prop-phase-stats-table.componet';
import AccountActionsBarPropComponent from '~/source/contact/contact-page/prop-account/trading-account-actions-bar-prop/trading-account-actions-bar-prop.component';
import { PurchaseLinkedDocumentsPopupComponent } from '~/source/contact/contact-page/prop-account/purchase-linked-documents-popup/purchase-linked-documents-popup.component';
import { PurchaseLinkDocumentPopupComponent } from '~/source/contact/contact-page/prop-account/purchase-link-document-popup/purchase-link-document-popup.component';
import { ProfitShareHistoryPopupComponent } from '~/source/contact/contact-page/prop-account/profit-share-history-popup/profit-share-history-popup';
import { ChallengePhaseExtraContainerComponent } from '~/source/contact/contact-page/prop-account/challenge-phase-extra-container/challenge-phase-extra-container.component';
import CustomerChallengePhaseSocketService from '~/source/contact/contact-page/prop-account/customer-challenge-phase-socket.service';
import ChallengeSocketService from '~/source/contact/contact-page/prop-account/challenge-account-container/challenge-socket.service';

export const ChallengeAccountModule = angular
  .module('crm.contact.challengeAccount', [])
  .service(
    'customerChallengePhaseSocketService',
    CustomerChallengePhaseSocketService,
  )
  .service('challengeSocketService', ChallengeSocketService)
  .component('prfChallengeAccountContainer', ChallengeAccountContainerComponent)
  .component('prfChallengePurchaseTable', ChallengePurchaseTableComponent)
  .component('prfChallengePhaseContainer', ChallengePhaseContainerComponent)
  .component(
    'prfChallengePhaseExtraContainer',
    ChallengePhaseExtraContainerComponent,
  )
  .component(
    'prfConfirmChallengePurchasePopup',
    ConfirmChallengePurchasePopupController as any,
  )
  .component('prfChallengeAccountInfo', ChallengeAccountInfoComponent)
  .component('prfPropPhaseStatsTable', PropPhaseStatsTableComponent)
  .component(
    'prfTradingAccountActionsBarProp',
    <any>AccountActionsBarPropComponent,
  )
  .component('prfTradingAccountProp', TradingAccountPropComponent)
  .component('prfPurchaseLinkDocumentPopup', PurchaseLinkDocumentPopupComponent)
  .component('prfProfitShareHistoryPopup', ProfitShareHistoryPopupComponent)
  .component(
    'prfPurchaseLinkedDocumentsPopup',
    PurchaseLinkedDocumentsPopupComponent,
  ).name;
