import { switchOn } from '../utilities/switch-on';

/**
 * Calc account type channel part.
 *
 * @param accountType
 * @return channel part
 */
export function calcAccountTypeChannelPart(accountType: string) {
  return switchOn(
    {
      forex: () => 'CustomerTradingAccount',
      binary: () => 'BinaryTradingAccount',
      prop: () => 'CustomerTradingAccount',
    },
    accountType,
    () => {
      throw new Error('unintented');
    },
  );
}
