import SocketService from '~/source/common/services/socket';

export class TradingAccountForexSocketService extends SocketService {
  get channelRoot() {
    return 'CustomerTradingAccount';
  }

  get whiteList() {
    return [
      'balance',
      'credit',
      'swap',
      'margin',
      'marginLevel',
      'freeMargin',
      'deposit',
      'netDeposit',
      'bonus',
      'withdrawable',
      'withdrawal',
      'equity',
      'turnover',
      'openVolume',
      'pnl',
      'winRate',
      'commission',
      'revenue',
      'leverage',
      'validPositionsCount',
      'openPositionsCount',
      'syncRemoteId',
      'isDemo',
      'isAccountActive',
      'name',
      'fee',
      'brokerFee',
      'rolloverFee',
      'internalTransfer',
      'statusCode',
      'marginLevelType',
    ];
  }
}

export default TradingAccountForexSocketService;
